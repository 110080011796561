import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Box, Button, TextField, useMediaQuery } from "@material-ui/core";

import MailingAddressForm from "./MailingAddressForm";
import { makeRootStyles } from "../../theme/styles";
import { config } from "../../config";

import {
  getStepIndex,
  useReturnStepsActions,
  useReturnStepsState,
  CONFIRM_MAILING_ADDRESS_STEP,
  CHOOSE_SHOP_NOW_STEP,
} from "../../contexts/returnSteps";
import { getTranslation } from "../../../shared/modules/config";
import ContactInformationForm from "./ContactInformationForm";
import { useTypeForm } from "../../contexts/typeForm";

const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    subtitle: {
      marginBottom: theme.spacing(1),
    },
    address: {},
    editButton: {
      cursor: "pointer",
      marginLeft: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    input: {
      backgroundColor: theme.palette.common.white,
    },
    backButton: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: "N0MailingAddress" },
);

// @TODO hook for retrieving billing address
// const EXAMPLE_ADDRESS = {
//   firstName: "John",
//   lastName: "Smith",
//   address1: "24 Market Building",
//   address2: "The Piazza",
//   city: "London",
//   province: "",
//   countryCode: "GB",
//   zip: "WC2E 8RD",
// };

function MailingAddress({ type, address, contact, notes }) {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const actions = useReturnStepsActions();
  const state = useReturnStepsState();
  const typeForm = useTypeForm();

  const isHomePickup = type === "homePickup";
  const isGuestReturn = type === "guestReturn";
  const requireContactInfo = isHomePickup;

  const [isFormOpen, toggleForm] = useState(isGuestReturn);
  const [isAddressValid, setIsAddressValid] = useState(!isGuestReturn);
  const [isContactValid, setIsContactValid] = useState(!isHomePickup);
  const isValid = isAddressValid && isContactValid;
  const useNewDesign = config.isNthUi;
  const showNextBtn =
    state.currentStepIndex === getStepIndex(CONFIRM_MAILING_ADDRESS_STEP);
  const showBackBtn = showNextBtn && useNewDesign;
  const showNav = showNextBtn || showBackBtn;

  const {
    firstName,
    lastName,
    address1,
    address2,
    city,
    province,
    zip,
    countryCode,
  } = address; // saved address in context state

  const showTwoColumns = requireContactInfo && !isFormOpen && isMdUp && false; // TODO: re-enable two columns layout when we have better address and contact info form
  const title = isHomePickup
    ? getTranslation("home_pickup_mailing_address_title")
    : state.items.some(i => i.exchange)
    ? getTranslation("gift_exchange_mailing_address_title")
    : getTranslation("gift_exchange_mailing_address_title_returns");

  const excludeFields = useMemo(() => ["phone", "submit"], undefined);

  const contactSubtitle =
    config.translations.home_pickup_contact_information_subtitle;
  const addressSubtitle = isHomePickup
    ? config.translations.home_pickup_mailing_address_subtitle
    : config.translations.gift_exchange_mailing_address_your_address;
  const editAddressButtonLabel =
    config.translations.gift_exchange_mailing_address_change;
  const notesLabel =
    config.translations.home_pickup_contact_information_form_notes;
  const submitButtonLabel = getTranslation(
    "gift_exchange_mailing_address_next_button_label",
  );
  const backButtonLabel = getTranslation("back");

  const handleAddressFormToggle = useCallback(() => {
    toggleForm(true);
    actions.goToStep(CONFIRM_MAILING_ADDRESS_STEP);
  }, []);

  const handleAddressChange = useCallback(
    ({ fromAddress, isValid }) => {
      setIsAddressValid(isValid);
      actions.setConfirmedMailingAddress({ ...address, ...fromAddress });
    },
    [address],
  );

  const handleContactChange = useCallback(
    ({ contact, isValid }) => {
      const { phone, ...others } = contact;
      setIsContactValid(isValid);
      actions.setConfirmedMailingAddress({
        ...address,
        phone,
      });
    },
    [address, contact],
  );

  const handleNotesChange = useCallback(
    event => {
      const key = event.target.name;
      const value = event.target.value;
      actions.setConfirmedMailingAddress({
        ...address,
        homePickupInstructions: value,
      });
    },
    [address],
  );

  const handleNext = useCallback(() => {
    toggleForm(false);
    actions.goToStep(CHOOSE_SHOP_NOW_STEP);
  }, []);

  const handleBack = useCallback(() => {
    toggleForm(false);
    actions.goBack();
    typeForm.scrollToActive();
  }, []);

  return (
    <Grid
      className={classes.root}
      justifyContent="center"
      spacing={2}
      container>
      <Grid item xs={12}>
        <Typography
          align="center"
          className={classes.title}
          variant="h1"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Grid spacing={4} container>
          {requireContactInfo && (
            <Grid xs={12} item>
              <Typography
                align="center"
                variant="h3"
                className={classes.subtitle}>
                {contactSubtitle}
              </Typography>
              <ContactInformationForm
                contact={contact}
                onChange={handleContactChange}
              />
              <TextField
                className={classes.input}
                margin="normal"
                variant="outlined"
                color="primary"
                fullWidth
                multiline
                rows={3}
                label={notesLabel}
                name="notes"
                value={notes || ""}
                onChange={handleNotesChange}
              />
              <Typography
                variant="caption"
                color="textSecondary"
                display="block"
                align="center">
                {
                  config.translations
                    .home_pickup_contact_information_form_pii_helper_text
                }
              </Typography>
            </Grid>
          )}
          <Grid xs={12} item>
            <Typography
              align="center"
              variant="h3"
              className={classes.subtitle}>
              {addressSubtitle}
            </Typography>
            {isFormOpen ? (
              <MailingAddressForm
                validateZip
                exclude={excludeFields}
                address={address}
                onSubmit={handleAddressChange}
                onChange={handleAddressChange}
                submitLabel={submitButtonLabel}
              />
            ) : (
              <Typography
                align="center"
                variant="body1"
                className={classes.address}
                component={Box}>
                <Typography>
                  {firstName} {lastName}
                </Typography>
                <Typography>{address1}</Typography>
                {address2 && <Typography>{address2}</Typography>}
                <Typography>{city}</Typography>
                <Typography>{`${province || ""} ${zip}`}</Typography>

                <Link
                  className={classes.editButton}
                  onClick={handleAddressFormToggle}>
                  {editAddressButtonLabel}
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {showNav && (
        <Grid xs={12} sm={6} md={4} item>
          {showNextBtn && (
            <Button
              id="confirm-mailing-address-next"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isValid}
              onClick={handleNext}>
              {submitButtonLabel}
            </Button>
          )}
          {showBackBtn && (
            <Button
              id="confirm-mailing-address-back"
              className={classes.backButton}
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleBack}>
              {backButtonLabel}
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
}

MailingAddress.propTypes = {
  type: PropTypes.oneOf(["guestReturn", "homePickup"]).isRequired,
  address: PropTypes.object,
  contact: PropTypes.object,
  notes: PropTypes.string,
};

MailingAddress.defaultProps = {
  type: "guestReturn",
};

export default MailingAddress;
