import React, { useState, useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

import LocationCard from "./LocationCard";
import Map from "../../../shared/components/MaptilerMap";
import ZipForm from "./ZipForm";

import Spinner from "../../../shared/components/Spinner";
import { makeRootStyles } from "../../theme/styles";
import {
  useReturnStepsActions,
  useReturnStepsState,
  CONFIRM_MAILING_ADDRESS_STEP,
} from "../../contexts/returnSteps";
import { config } from "../../config";
import { useReturnLocations } from "../../data/returnLocations";
import compact from "lodash/compact";
import Link from "@material-ui/core/Link";
import locale from "../../../shared/modules/lang";

const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    skipContainer: {
      marginTop: theme.spacing(2),
      height: theme.spacing(3),
      textAlign: "center",
    },
    skipLink: {},
    title: {
      marginBottom: theme.spacing(2),
    },
    mapDescription: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    mapDescriptionContent: {
      width: "100%",
      textAlign: "center",
    },
    mapDescriptionTitle: {
      marginBottom: theme.spacing(1),
    },
    mapContainer: {
      border: "1px solid #ccc",
      background: theme.palette.common.white,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      minHeight: theme.spacing(37),
      justifyContent: "space-between",
      [theme.breakpoints.ie11()]: { height: theme.spacing(40) },
      [theme.breakpoints.edge()]: { height: theme.spacing(40) },
    },
    mapContainerElement: {
      // maxHeight: 300,
    },
  }),
  { name: "N0ChooseLocation" },
);

function ChooseLocation({
  countryCode,
  zipcode,
  carrier,
  allowProceedWithoutLocation,
  qr,
}) {
  const classes = useStyles();
  const { locationId } = useReturnStepsState();
  const actions = useReturnStepsActions();
  const {
    state: { data: locations, error, loading },
    actions: { getLocations },
  } = useReturnLocations();
  const [zipFormOpen, toggleZipForm] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [localLocationId, setLocalLocation] = useState(locationId);
  const selectedLocation = useMemo(() => {
    const location =
      localLocationId && locations.find(l => l.id === localLocationId);
    return location || locations[0];
  }, [localLocationId, locations]);

  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const filteredLocations = useMemo(
    () =>
      locations.slice(
        currentPage * pageSize,
        currentPage * pageSize + pageSize,
      ),
    [locations, currentPage, pageSize],
  );

  const handleStoreSearch = args => {
    getLocations({ ...args, carrier: carrier, qr: qr, locale });
    toggleZipForm(false);
  };

  const handleToggleForm = () => toggleZipForm(current => !current);

  const createClickLocationHandler = location => () => {
    setLocalLocation(location.id);
  };

  const createSelectLocationHandler = location => () => {
    setLocalLocation(location.id);
    actions.setStoreLocation({ ...location, carrier });
    actions.goToStep(CONFIRM_MAILING_ADDRESS_STEP);
  };

  const handleOnMount = () =>
    getLocations({ countryCode, zipcode, carrier, qr: qr });

  useEffect(handleOnMount, [carrier]);

  const handleSkip = () => {
    actions.goToStep(CONFIRM_MAILING_ADDRESS_STEP);
  };

  return (
    <Grid className={classes.root} spacing={2} container>
      <Grid xs={12} item>
        <Typography align="center" className={classes.title} variant="h1">
          {carrier == "in_store"
            ? config.translations.choose_method_substep_in_store_title
            : config.translations.choose_method_substep_concierge_title}
        </Typography>
        <ZipForm
          initialCountryCode={countryCode}
          initialZip={zipcode}
          isOpen={zipFormOpen}
          error={
            error && (
              <Typography align="center" color="error" variant="h5">
                {error}
              </Typography>
            )
          }
          loading={loading}
          onSearch={handleStoreSearch}
          toggleForm={handleToggleForm}
        />
        {filteredLocations.length > 0 ? (
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {filteredLocations.map(location => (
                <LocationCard
                  key={location.id}
                  address={location.storeAddress}
                  disableMap={isMdUp}
                  onClick={createClickLocationHandler(location)}
                  onChoose={createSelectLocationHandler(location)}
                  selected={selectedLocation.id === location.id}
                  title={`${location.storeAddress.address1} ${location
                    .storeAddress.address2 || ""}`}
                  name={location.storeName}
                  subtitle={location.storeName2}
                  logoUrl={location.logoUrl}
                  longitude={location.longitude}
                  latitude={location.latitude}
                  carrier={carrier}
                />
              ))}
            </Grid>
            {isMdUp && (
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className={classes.mapContainer}>
                  <Map
                    address={selectedLocation.storeAddress}
                    className={classes.mapContainerElement}
                    latitude={selectedLocation.latitude}
                    longitude={selectedLocation.longitude}
                  />
                  <div className={classes.mapDescription}>
                    <Typography
                      variant="h4"
                      className={clsx(
                        classes.mapDescriptionTitle,
                        classes.mapDescriptionContent,
                      )}>
                      {selectedLocation.storeName}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.mapDescriptionContent}>
                      {compact([
                        selectedLocation.storeAddress.address1,
                        selectedLocation.storeAddress.address2,
                      ]).join(", ")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.mapDescriptionContent}>
                      {selectedLocation.storeAddress.city},{" "}
                      {selectedLocation.storeAddress.state}{" "}
                      {selectedLocation.storeAddress.zip}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.mapDescriptionContent}>
                      {selectedLocation.storeAddress.phone}
                    </Typography>
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        ) : (
          loading && <Spinner />
        )}

        {allowProceedWithoutLocation && (
          <div className={classes.skipContainer}>
            <Link
              id="choose-method-substep-skip"
              className={classes.skipLink}
              onClick={handleSkip}>
              {config.translations.choose_method_substep_concierge_skip}
            </Link>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default ChooseLocation;
