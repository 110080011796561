import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={70}
      height={70}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.678 47.92a.606.606 0 01-.609.604H17.036a.606.606 0 01-.608-.603v-9.48c0-.333.272-.605.608-.605H27.07c.337 0 .609.272.609.604v9.48zm29.625-21.516l-7.117-13.958a1.367 1.367 0 00-1.22-.75l-9.853-.01 1.731 14.718v5.949c0 .542-.464.982-1.038.982h-9.258c-.573 0-1.037-.44-1.037-.982v-5.949l1.724-14.728-10.254.006a1.362 1.362 0 00-1.204.73l-7.08 13.992v31.734c0 .947.775 1.715 1.73 1.715H29.51V53.51c0-.58.464-1.051 1.037-1.051h9.259c.573 0 1.037.47 1.037 1.05v6.344h14.73c.954 0 1.729-.768 1.729-1.715V26.404z"
        fill="#E7E7E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.486 40.854h-2.71a.675.675 0 01-.672-.679c0-.374.3-.678.673-.678h2.71c.372 0 .672.304.672.678 0 .375-.3.679-.673.679zM25.49 43.568h-6.662c-.401 0-.724-.304-.724-.678 0-.375.323-.679.724-.679h6.662c.4 0 .724.304.724.679 0 .374-.324.678-.724.678zM25.49 45.604h-6.662c-.401 0-.724-.304-.724-.679 0-.374.323-.678.724-.678h6.662c.4 0 .724.303.724.678 0 .375-.324.679-.724.679z"
        fill="#231F20"
      />
      <mask
        id="prefix__a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={12}
        y={10}
        width={46}
        height={50}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.022 10.658h45.764v49.195H12.022V10.658z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.37 57.45c0 .552-.454 1-1.012 1H41.423v-5.53-.06c0-.963-.78-1.744-1.742-1.744h-9.204c-.96 0-1.741.781-1.741 1.744v5.59H14.449a1.006 1.006 0 01-1.011-1V26.67h15.298v5.202c0 .923.781 1.675 1.741 1.675h9.204c.961 0 1.742-.752 1.742-1.675l-.004-5.201H56.37v30.78zm-16.365 1h-9.852l.001-5.53c0-.188.146-.34.325-.34h9.204c.177 0 .32.151.322.337v5.533zm-19.61-46.037a.65.65 0 01.57-.347l9.399-.006-1.55 13.208H13.878l6.516-12.855zm19.61 14.258v5.2c0 .15-.145.273-.324.273h-9.204c-.178 0-.324-.122-.324-.272l-.003-5.201h9.855zM38.52 13.407l1.4 11.861h-9.68l1.552-13.207 6.57.007.158 1.339zm10.266-1.326c.248 0 .47.136.584.359l6.555 12.828H41.346L39.79 12.073l8.997.008zm8.924 13.572l-7.075-13.844a2.06 2.06 0 00-1.847-1.131l-9.165-.008v-.002l-8.463-.01h-.631l-9.57.005a2.067 2.067 0 00-1.825 1.111l-7.038 13.881a.7.7 0 00-.074.314v31.482c0 1.325 1.088 2.402 2.427 2.402h40.909c1.339 0 2.428-1.077 2.428-2.402V25.969a.699.699 0 00-.076-.316z"
          fill="#231F20"
        />
      </g>
      <mask
        id="prefix__b"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={12}
        y={10}
        width={46}
        height={51}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.021 60.532H57.98V10.998H12.02v49.534z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.845 47.57h9.953v-9.361h-9.953v9.36zm10.06-10.787H16.74c-.738 0-1.34.593-1.34 1.323v9.567c0 .73.602 1.323 1.34 1.323h10.164c.738 0 1.338-.593 1.338-1.323v-9.567c0-.73-.6-1.323-1.338-1.323z"
          fill="#231F20"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
