import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { config } from "../../config";
import Typography from "@material-ui/core/Typography";
import Spinner from "../../../shared/components/Spinner";
import React from "react";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import { makeRootStyles } from "../../theme/styles";
import MailingAddressForm from "../ConfirmMailingAddress/MailingAddressForm";
import { getTranslation } from "../../../shared/modules/config";
import { mergeAddress } from "../../modules/address";
import { getAddressError } from "../../../shared/modules/decodeError";

const useStyles = makeRootStyles(
  theme => ({
    submitContainer: {
      marginTop: theme.spacing(4),
    },
    submitButton: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: "N0ReviewSubmitButton" },
);

const SubmitButton = ({
  showContactRetailer,
  isCharge,
  handleSubmit,
  paymentIdPresent,
  loading,
}) => {
  const classes = useStyles();
  const onClick = () => handleSubmit();
  const customerOwesMoneyAndShouldContactRetailer =
    showContactRetailer && isCharge && !paymentIdPresent;
  return (
    <Grid xs={12} sm={6} md={4} item>
      <Button
        id="review-submit"
        className={classes.submitButton}
        color="primary"
        disabled={loading || customerOwesMoneyAndShouldContactRetailer}
        onClick={onClick}
        size="large"
        variant="contained"
        type="submit"
        fullWidth>
        <strong>{config.translations.return_review_submit_return}</strong>
      </Button>
      {customerOwesMoneyAndShouldContactRetailer && (
        <Typography align="center" variant="body1">
          {config.translations.return_review_error_contact_retailer}
        </Typography>
      )}
    </Grid>
  );
};

export const Submit = ({
  submitStatus,
  state,
  errorMessage,
  isErrorSnackbarVisible,
  isCharge,
  showContactRetailer,
  paymentIdPresent,
  handleSubmit,
  loading,
}) => {
  const classes = useStyles();
  const addressError = getAddressError(submitStatus?.errorDetail);

  return (
    <Grid
      className={classes.submitContainer}
      container
      justifyContent="center"
      alignItems="center">
      {isErrorSnackbarVisible && (
        <Grid xs={12} item>
          <ErrorSnackbar message={errorMessage} />
        </Grid>
      )}
      {addressError && (
        <MailingAddressForm
          // preserve user input address, but override with server suggested fields
          address={mergeAddress(state.fromAddress, addressError.address)}
          onSubmit={({ fromAddress }) => handleSubmit({ fromAddress })}
          disable={addressError.disableFields}
          submitLabel={getTranslation("bad_mailing_address_submit")}
          validateZip={true}
        />
      )}
      {!state.submitted && !addressError && (
        <>
          <SubmitButton
            {...{
              submitStatus,
              isCharge,
              showContactRetailer,
              handleSubmit,
              paymentIdPresent,
              loading,
            }}
          />
          {loading && (
            <Grid xs={12} item>
              <Spinner />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
