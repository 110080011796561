import { useMemo, useCallback } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { GraphQLError } from "graphql";
import {
  featureFlaggedHook,
  useRequestStatus,
} from "../../shared/modules/hooks";
import { memoObjectByKeyValues } from "../../shared/modules/object";
import {
  BOXLESS_METHOD,
  METHOD_IN_STORE,
  METHOD_KEEP_THE_ITEM,
  METHOD_SHIP_ON_YOUR_OWN,
  METHOD_XPO,
  PRINTERLESS_METHOD,
} from "../../retailer-app/constants/returns";
import { DODDLE } from "../../retailer-app/constants/carriers";
import { config } from "../config";

export const GET_USER_PREFERENCES = gql`
  query GetUserPreferences($email: String!) {
    userPreferences(email: $email) {
      carriers
      locations
      returnMethods
      profile {
        profileId
      }
    }
  }
`;

export const SET_USER_PREFERENCES = gql`
  mutation setUserPreferences(
    $orderId: String!
    $email: String!
    $userPreferences: UserPreferencesInput!
  ) {
    setUserPreferences(
      orderId: $orderId
      email: $email
      userPreferences: $userPreferences
    )
  }
`;

export const useConsumerPreferences = featureFlaggedHook(
  config.isUserPreferencesEnabled,
  {
    queryStatus: {},
    updateStatus: {},
  },
)(email => {
  const saved = useQuery(GET_USER_PREFERENCES, {
    variables: { email },
    skip: !email,
  });
  const [update, updateState] = useMutation(SET_USER_PREFERENCES);
  const queryStatus = useRequestStatus(saved);
  const updateStatus = useRequestStatus(updateState);

  const consumerPreferences = saved.data?.userPreferences;

  const preferencesStatus = useMemo(() => {
    if (!email) return undefined;

    const status =
      queryStatus.error || updateStatus.error
        ? "error"
        : queryStatus.loading || updateStatus.loading
        ? "loading"
        : updateStatus.called
        ? "success"
        : "idle";
    const profileStatus =
      status === "success"
        ? "pending"
        : consumerPreferences?.returnMethods?.length > 0 &&
          consumerPreferences?.profile?.profileId
        ? "active"
        : undefined;
    const profileLink = config.userPreferencesEndpoint;
    return { status, profileStatus, profileLink };
  }, [email, consumerPreferences, queryStatus, updateStatus]);

  const updateConsumerPreferences = useCallback(
    (orderId, email, userPreferences) =>
      update({
        variables: { orderId, email, userPreferences },
        update: (cache, result) => {
          const apiResult = result.data.setUserPreferences;

          if (
            apiResult === "PREF_UPDATED" ||
            apiResult === "PREF_UNVERIFIED_NO_UPDATE"
          ) {
            let data = cache.readQuery({
              query: GET_USER_PREFERENCES,
              variables: { email },
            });
            data = { ...data, userPreferences };

            cache.writeQuery({
              query: GET_USER_PREFERENCES,
              variables: { email },
              data,
            });
          } else {
            throw new GraphQLError("Unable to set user preferences", {
              extensions: {
                code: apiResult ?? "PREF_ERROR",
              },
            });
          }
        },
      }),
    [update],
  );

  return memoObjectByKeyValues({
    consumerPreferences,
    preferencesStatus,
    queryStatus,
    updateStatus,
    updateConsumerPreferences,
  });
});

export const toReturnMethodPreference = method => {
  if (
    method.qr === BOXLESS_METHOD ||
    (method.id === DODDLE && method.qr === PRINTERLESS_METHOD)
  )
    return "boxless";
  if (method.qr === PRINTERLESS_METHOD) return "printerless_mail";
  if (method.id === METHOD_IN_STORE) return "store";
  if (method.id === METHOD_XPO) return "home_pickup";
  if (method.id === METHOD_SHIP_ON_YOUR_OWN) return "mail";
  if (![METHOD_KEEP_THE_ITEM, METHOD_IN_STORE].includes(method.id))
    return "concierge";
  return null;
};

export const isPreferredMethod = (method, preferences) => {
  if (!preferences) return false;

  const type = toReturnMethodPreference(method);
  return preferences.returnMethods?.includes?.(type) ?? false;
};
