import { useQuery, gql } from "@apollo/client";
import {
  featureFlaggedHook,
  useRequestStatus,
} from "../../shared/modules/hooks";
import { memoObjectByKeyValues } from "../../shared/modules/object";
import { RETURN_CREDIT_METHOD_RESHOP } from "../../retailer-app/constants/returns";
import { pick } from "lodash/fp";
import { config } from "../config";

const QUERY = gql`
  query preApproval($preApprovalInput: PreApprovalInput!) {
    preApproval(preApprovalInput: $preApprovalInput) {
      result {
        preApprovalId
      }
      userErrors {
        code
        message
      }
    }
  }
`;

export const usePreApproval = featureFlaggedHook(
  config.isReshopEarlyHardApproval,
  { status: {} },
)(input => {
  const requirePreApproval =
    input?.chosenRefundMethod === RETURN_CREDIT_METHOD_RESHOP &&
    !!input?.preApprovalReturnUuid &&
    input?.refundCalculated?.cents > 0;
  const saved = useQuery(QUERY, {
    variables: {
      preApprovalInput: pick(
        [
          "email",
          "exchangeItems",
          "orderNumber",
          "preApprovalReturnUuid",
          "refundCalculated",
          "reshopOptions",
          "returnItems",
        ],
        input,
      ),
    },
    skip: !requirePreApproval,
  });
  const status = useRequestStatus(saved, "preApproval.userErrors");
  const state = saved?.data?.preApproval?.result;

  return memoObjectByKeyValues({
    state,
    status,
  });
});
