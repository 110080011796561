import { useState, useMemo, useCallback } from "react";
import { useMutation, gql } from "@apollo/client";
import get from "lodash/get";

import { config } from "../config";
import { useUploadPictures } from "./uploadPictures";
import { getHandledErrorMessage } from "../../shared/modules/decodeError";
import { memoObjectByKeyValues } from "../../shared/modules/object";

const SUBMIT_RETURN = gql`
  mutation submitReturn(
    $fromAddress: FromAddressInput
    $orderNumber: String!
    $draftReturnUuid: String
    $email: String!
    $returnItems: [ReturnCalculationInput!]!
    $exchangeItems: [ExchangeItemInput!]!
    $shopifyOrderId: String!
    $presentmentCurrency: String!
    $shippingPrice: Float
    $shippingMethod: String!
    $carrierServiceName: String
    $locationId: String
    $location: DropoffLocationInput
    $locale: String
    $selectedRefundMethod: String
    $paymentMethodId: String
    $customerPmtId: String
    $shopNow: Boolean
    $csid: String
    $homePickupInstructions: String
    $preApprovalReturnUuid: String
    $preApprovalId: String
    $requestedLabelCount: Int
    $reshopOptions: ReshopOptionsInput
  ) {
    submitReturn(
      locale: $locale
      returnItems: $returnItems
      draftReturnUuid: $draftReturnUuid
      exchangeItems: $exchangeItems
      shopifyOrderId: $shopifyOrderId
      presentmentCurrency: $presentmentCurrency
      shippingPrice: $shippingPrice
      orderNumber: $orderNumber
      email: $email
      shippingMethod: $shippingMethod
      carrierServiceName: $carrierServiceName
      locationId: $locationId
      location: $location
      selectedRefundMethod: $selectedRefundMethod
      paymentMethodId: $paymentMethodId
      customerPmtId: $customerPmtId
      fromAddress: $fromAddress
      shopNow: $shopNow
      csid: $csid
      homePickupInstructions: $homePickupInstructions
      preApprovalReturnUuid: $preApprovalReturnUuid
      preApprovalId: $preApprovalId
      requestedLabelCount: $requestedLabelCount
      reshopOptions: $reshopOptions
    )
  }
`;

export const useSubmitReturn = () => {
  const directUpload = useUploadPictures();

  const [mutate, mutated] = useMutation(SUBMIT_RETURN);
  const [picturesUploading, setPicturesUploading] = useState(false);

  const getBlobIdForPic = async file => {
    const res = await directUpload.uploadImage(file);
    return res.signedBlobId;
  };

  const submitReturn = useCallback(
    async variables => {
      try {
        setPicturesUploading(true);
        variables.returnItems = await Promise.all(
          variables.returnItems.map(async ri => {
            return {
              ...ri,
              pictures:
                ri.pictures && get(ri, "pictures.length")
                  ? await Promise.all(
                      ri.pictures.map(async pic => await getBlobIdForPic(pic)),
                    )
                  : undefined,
            };
          }),
        );

        variables.exchangeItems = await Promise.all(
          variables.exchangeItems.map(async ri => {
            return {
              ...ri,
              returnItem: {
                ...ri.returnItem,
                pictures:
                  ri.returnItem.pictures &&
                  get(ri, "returnItem.pictures.length")
                    ? await Promise.all(
                        ri.returnItem.pictures.map(
                          async pic => await getBlobIdForPic(pic),
                        ),
                      )
                    : undefined,
              },
            };
          }),
        );

        setPicturesUploading(false);
        return mutate({ variables });
      } catch (err) {
        setPicturesUploading(false);
      }
    },
    [mutate],
  );

  const submitStatus = useMemo(() => {
    let errorMessage = getHandledErrorMessage(
      mutated.error,
      config.translations.return_review_submit_error,
    );
    if (directUpload.status.error) {
      errorMessage = config.translations.return_review_submit_error;
    }

    const loading = mutated.loading || picturesUploading;

    return {
      loading,
      error: loading ? null : errorMessage,
      data: get(mutated, "data.submitReturn", null),
      errorDetail: loading ? null : mutated.error,
    };
  }, [
    mutated.loading,
    mutated.data,
    mutated.error,
    picturesUploading,
    directUpload.status,
  ]);

  return memoObjectByKeyValues({
    submitReturn,
    submitStatus,
    submitStatusReset: mutated.reset,
  });
};
