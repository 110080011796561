import React from "react";
import get from "lodash/get";

import Typography from "@material-ui/core/Typography";

import { makeRootStyles } from "../theme/styles";

const useStyles = makeRootStyles(
  theme => ({
    textLogo: {
      textTransform: "uppercase",
      letterSpacing: "0.1rem",
    },
    carrierLogo: {
      height: 50,
    },
  }),
  { name: "N0ShopLogo" },
);

const DropoffLocationLogo = ({ name, carrier, url = null }) => {
  const classes = useStyles();

  const logoImg = get(window, "nvo_config.logo_img", "");

  // With concierge we get a URL for the logo of the drop-off location
  if (url) return <img src={url} className={classes.carrierLogo} />;

  // OK for rendering string, this comes directly from Shopify's liquid template
  if (logoImg && !logoImg.includes("no-image") && carrier == "in_store")
    return <div dangerouslySetInnerHTML={{ __html: logoImg }}></div>;

  return (
    <Typography variant="h4" className={classes.textLogo}>
      <strong>{name}</strong>
    </Typography>
  );
};

export default DropoffLocationLogo;
