import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { pick, isNumber } from "lodash";

import { Map, Marker, NavigationControl } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { maptilerKey } from "../modules/config";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import errorNotifier from "../modules/error-notifier";

const MAPTILER_KEY = maptilerKey;
const MAPTILER_MAP_ID = "10d44f10-b441-483a-bb86-5850e28f98b1";
const MAPTILER_URL = `https://api.maptiler.com/maps/${MAPTILER_MAP_ID}/?key=${MAPTILER_KEY}#1.37/0/0`;
const MAPTILER_STYLE = `https://api.maptiler.com/maps/${MAPTILER_MAP_ID}/style.json?key=${MAPTILER_KEY}`;

export const useStyles = makeStyles(
  theme => ({
    root: {
      width: "100%",
      height: "100%",
    },
    map: {
      width: "100%",
      height: "100%",
    },
    error: {
      padding: theme.spacing(2),
    },
  }),
  { name: "N0MaplibreMap" },
);

/**
 * maplibre-gl coordinate object
 * @typedef {Object} Coordinate
 * @property {number} lng longitude
 * @property {number} lat latitude
 */
/**
 * map object or numbers to maplibre-gl coordinate object
 * @param {number|Coordinate|Object} arg1             coordinate object or longitude
 * @param {number}                   [arg1.longitude] longitude
 * @param {number}                   [arg1.latitude]  latitude
 * @param {number}                   [arg2]           latitude
 * @returns {Coordinate}
 */
const mapCoor = (arg1, arg2) => {
  if (isNumber(arg1) && isNumber(arg2)) {
    return { lng: arg1, lat: arg2 };
  }
  if (isNumber(arg1?.longitude) && isNumber(arg1?.latitude)) {
    return { lng: arg1.longitude, lat: arg1.latitude };
  }
  if (isNumber(arg1?.lng) && isNumber(arg1?.lat)) {
    return pick(arg1, ["lng", "lat"]);
  }
  return null;
};

export async function fetchCoordinatesFromAddress(address) {
  const { address1, address2, city, state, zip } = address;
  // const street = `${address1} ${address2}`;
  const street = `${address1}`; // address2 may lead to incorrect locations
  const queryString = encodeURIComponent(`${street} ${city} ${state} ${zip}`);
  const url = `https://api.maptiler.com/geocoding/${queryString}.json?key=${MAPTILER_KEY}`;
  const response = await fetch(url, { method: "GET" });
  let { features } = await response.json();
  features = features.map(d => ({
    ...d,
    ...mapCoor(...d.center),
  }));
  return features;
}

function MaptilerMap(props) {
  let {
    address,
    latitude,
    longitude,
    start,
    markersColor,
    disableDefaultMarker,
    markers,
    className = "",
    ...otherProps
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [id] = useState(new Date().getTime());
  const EL_ID = `mapblire-${id}`;
  const mapEl = useRef(null);
  const [mapObj, setMapObj] = useState(null);
  const startWithCoor = start && mapCoor(start);
  const startWithAddress = start && !startWithCoor;
  const [_start, setStart] = useState(startWithCoor);
  const [_center, setCenter] = useState(
    start ? null : mapCoor(longitude, latitude),
  );
  const [mapInitFailed, setMapInitFailed] = useState(false);

  const _markers = useMemo(() => {
    let ret = disableDefaultMarker || !_center ? [] : [_center];
    ret = ret.concat(markers);
    return ret;
  }, [disableDefaultMarker, _center, markers]);

  const createMarkers = (map, markers) =>
    markers.map(marker =>
      new Marker({
        color: marker.color || markersColor || theme.palette.secondary.main,
        scale: 0.8,
      })
        .setLngLat([marker.lng, marker.lat])
        .addTo(map),
    );

  const mapInit = (center, markers) => {
    const { lng = 0, lat = 0 } = center;

    try {
      const map = new Map({
        container: EL_ID, // container id
        style: MAPTILER_STYLE, // style URL
        center: [lng, lat], // starting position [lng, lat]
        zoom: 15, // starting zoom
      });
      const nav = new NavigationControl({ showCompass: false });
      map.addControl(nav);
      setMapInitFailed(false);
      return { map, nav, markers: createMarkers(map, markers) };
    } catch (err) {
      errorNotifier.warn(err);
      setMapInitFailed(true);
      return null;
    }
  };

  // cleanup function
  useEffect(() => () => mapObj?.map?.remove(), [mapObj?.map]);

  useEffect(() => {
    // Initialize the map
    if (!mapObj) {
      if (_start) {
        setMapObj(mapInit(_start, _markers));
      } else if (_center) {
        setMapObj(mapInit(_center, _markers));
      }
      return;
    }

    // Update location
    if (_center) {
      mapObj.map.flyTo({
        center: [_center.lng, _center.lat],
      });
    }
  }, [_start, _center]);

  useEffect(() => {
    if (start && !_start) return; // loading starting location

    let center = mapCoor(longitude, latitude);
    if (center) {
      setCenter(center);
    } else if (address) {
      // query the coordinate from address object
      (async () => {
        const res = await fetchCoordinatesFromAddress(address);
        if (res && res[0]) {
          setCenter(mapCoor(res[0]));
        }
      })();
    }
  }, [start, _start, address, latitude, longitude]);

  useEffect(() => {
    // query the coordinate from address object
    if (startWithAddress && start) {
      (async () => {
        const res = await fetchCoordinatesFromAddress(start);
        if (res && res[0]) {
          setStart(mapCoor(res[0]));
        }
      })();
    }
  }, [startWithAddress, start]);

  useEffect(() => {
    if (mapObj) {
      // redraw all markers
      mapObj.markers.forEach(marker => marker.remove());

      const newMapObj = {
        ...mapObj,
        markers: createMarkers(mapObj.map, _markers),
      };
      setMapObj(newMapObj);
    }
  }, [_markers]);

  return (
    <div className={clsx(classes.root, className)} {...otherProps}>
      <div id={EL_ID} ref={mapEl} className={classes.map}>
        {mapInitFailed && (
          <div className={classes.error}>
            Cannot render the map, please enable hardware acceleration / WebGL
            in browser settings.
          </div>
        )}
      </div>
    </div>
  );
}

const Coordinate = PropTypes.shape({
  lng: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
});
const Address = PropTypes.shape({
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
});
MaptilerMap.propTypes = {
  address: Address,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  start: PropTypes.oneOfType([Address, Coordinate]), // starting location of map initial animation
  disableDefaultMarker: PropTypes.bool,
  markersColor: PropTypes.string,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lng: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
      color: PropTypes.string,
    }),
  ),
};

MaptilerMap.defaultProps = {
  markers: [],
};

export default MaptilerMap;
