import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";

import TypeFormSection from "./TypeFormSection";
import {
  // getWindowFramePosition,
  scrollToPosition,
} from "../../../shared/modules/frame";
import { getBranding } from "../../../shared/modules/config";
import { useTypeForm } from "../../contexts/typeForm";
import { useDebounceCallback } from "../../../shared/modules/hooks";

function TypeForm({ activeIndex, children }) {
  const actions = useTypeForm();
  const scrollToActive = useDebounceCallback(actions.scrollToActiveNow, 350);

  const handleRenderNext = useCallback(
    function handleRenderNext(activeSection, activeIndex, firstRender) {
      actions.setActiveSection(activeSection);

      // Disable autoscroll on the first step.
      if (firstRender && activeIndex !== 0 && activeIndex !== 1) {
        scrollToActive();
      }
    },
    [actions],
  );

  return React.Children.map(children, (child, index) => {
    if (index <= activeIndex) {
      return (
        <TypeFormSection
          activeIndex={activeIndex}
          isNext={activeIndex === index}
          onRenderNext={handleRenderNext}>
          {child}
        </TypeFormSection>
      );
    }
    return null;
  });
}
TypeForm.propTypes = {
  activeIndex: PropTypes.number,
  children: PropTypes.node,
};
TypeForm.defaultProps = {
  activeIndex: 0,
};
export default TypeForm;
