import React, { useState, useMemo } from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, ThemeProvider } from "@material-ui/styles";

import useTheme from "../theme/mui";
import errorNotifier from "../../shared/modules/error-notifier";
import { config } from "../config";
import { getConfig } from "../../shared/modules/config";

const useStyles = makeStyles(
  theme => ({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: "9.375em",
      paddingBottom: "9.375em",
    },
    message: {
      marginBottom: "2em",
    },
  }),
  { name: "N0ErrorBoundaryContent" },
);

function ErrorBoundaryContent() {
  const theme = useTheme(getConfig("branding"));
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container>
        <Grid justifyContent="center" container className={classes.container}>
          <Grid className={classes.message} xs={12} item>
            <Typography align="center" variant="h2">
              Sorry, something unexpected occurred and we&rsquo;re looking into
              it. Please try again later.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught:", error, errorInfo);
    errorNotifier.critical(error, { caughtByErrorBoundary: true });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryContent />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
