import React, { useEffect, useMemo, useCallback } from "react";
import clsx from "clsx";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { isEmpty, minBy, omit, some, unset } from "lodash/fp";

import { config } from "../../config";
import { makeRootStyles } from "../../theme/styles";
import { palette } from "../../../shared/theme/create-theme";
import locale from "../../../shared/modules/lang";

import {
  useReturnStepsActions,
  useReturnStepsState,
  getStepIndex,
  CHOOSE_RETURN_CREDIT_METHOD_STEP,
  CHOOSE_SHOP_NOW_STEP,
  useRegisterStepStatus,
} from "../../contexts/returnSteps";
import { useCalculateRefund } from "../../data/calculateRefund";
import { buildItemsByType } from "../../modules/items";
import { interpolate } from "../../../shared/modules/template";
import Spinner from "../../../shared/components/Spinner";
import { useSubmitReturn } from "../../data/submitReturn";
import { isValidHttpUrl } from "../../../shared/modules/string";
import { redirectToCheckout } from "../../../shared/modules/frame";
import MailingAddressForm from "../ConfirmMailingAddress/MailingAddressForm";
import { mergeAddress } from "../../modules/address";
import { ignoreEmpty, omitDeep } from "../../../shared/modules/object";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import Strikethrough from "../../components/Strikethrough";
import {
  getAddressError,
  getHandledErrorMessage,
} from "../../../shared/modules/decodeError";
import { calculateReturnMetrics, useEmitMetrics } from "../../data/emitMetric";
import {
  convertFromDatadogMetrics,
  countingEvent,
  SUBMIT_RETURN_SUCCESS_EVENT,
  useAbTesting,
} from "../../data/abTesting";
import {
  RETURN_CREDIT_METHOD_REFUND,
  RETURN_CREDIT_METHOD_SHOPNOW,
} from "../../../retailer-app/constants/returns";

const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    title: {},
    description: {},
    refundAmount: {},
    refundAmountOriginal: {},
    text: {
      marginBottom: theme.spacing(2.5),
    },
    incentiveText: {
      color: palette.red,
    },
    notes: {
      marginBottom: theme.spacing(4),
      color: "#999999",
    },
    incentiveContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(1),
    },
    chooseButton: {},
    skipButton: {},
  }),
  { name: "N0ChooseShopNow" },
);

const ChooseShopNow = ({ orderFetchData, draftReturnUuid }) => {
  const classes = useStyles();
  const state = useReturnStepsState();
  const actions = useReturnStepsActions();
  const { emitMetrics } = useEmitMetrics();
  const abTesting = useAbTesting();

  const submittingRedirect = state.shopNowSession?.redirectingToShop;

  const { order, returnReasonsById = {} } = orderFetchData;
  const { itemsById } = order;

  const { exchangeItems, returnItems } = useMemo(
    () => buildItemsByType(state.items, itemsById, returnReasonsById),
    [itemsById, returnReasonsById, state.items],
  );

  const selectedReturnMethod = state.selectedReturnMethod;
  const calculateParams = useMemo(
    () => ({
      carrierServiceName: selectedReturnMethod.carrierService,
      draftReturnUuid,
      exchangeItems: exchangeItems.map(unset("returnItem.pictures")),
      returnItems: returnItems.map(unset("pictures")),
      shopifyOrderId: order.shopifyOrderId,
      presentmentCurrency: order.presentmentCurrency,
      shippingPrice: selectedReturnMethod.price,
      shippingMethod: selectedReturnMethod.id,
      orderNumber: state.orderNumber,
      email: state.email,
      locale,
      fromAddress: ignoreEmpty(state.fromAddress),
      csid: state.csid,
      chosenRefundMethod: config.isShopNowEnabled ? "shop_now" : null,
    }),
    [
      exchangeItems,
      returnItems,
      order,
      selectedReturnMethod,
      state.orderNumber,
      state.email,
      locale,
      state.fromAddress,
      state.csid,
    ],
  );
  const { refund, queryStatus } = useCalculateRefund(calculateParams);

  // calculate refund would run again in review, hide the spinner for that
  const loading = isEmpty(refund) && queryStatus.loading;
  const queryErrorMessage = getHandledErrorMessage(
    queryStatus.error,
    config.translations.general_loading_error,
  );

  // Compare the shop now exchange credit to other available refund method
  // which would refund the least. Show as incentive if shop now credit is
  // more.
  const nonShopNowMethods = refund?.refundMethodsAvailable?.filter(
    v => v.refundMethod !== RETURN_CREDIT_METHOD_SHOPNOW,
  );
  const baselineMethod = minBy("refundCents", nonShopNowMethods);
  const refundAmount = baselineMethod?.refundFormatted;
  const refundAmountCents = baselineMethod?.refundCents;
  const incentive = "";
  const shopNowRefundMethod = refund?.refundMethodsAvailable?.find(
    v => v.refundMethod === RETURN_CREDIT_METHOD_SHOPNOW,
  );
  const creditAmount = shopNowRefundMethod?.refundFormatted;
  const creditAmountCents = shopNowRefundMethod?.refundCents;
  const showIncentive =
    Math.abs(refundAmountCents + 10) < Math.abs(creditAmountCents);

  const allowRefundToOriginalPayment = (
    refund?.refundMethodsAvailable || []
  ).some(v => v.refundMethod === "original_payment");
  const skipShopNow =
    !config.isShopNowEnabled ||
    queryErrorMessage || // skip shop now step to avoid broken flow, because it might be disabled by the rule engine
    refund?.shopNowAvailable === false ||
    refund?.totalRefundCents <= 0 ||
    (refund?.refundMethodsAvailable && !shopNowRefundMethod);

  const handleRefund = useCallback(() => {
    actions.goToStep(CHOOSE_RETURN_CREDIT_METHOD_STEP);
  }, []);

  useEffect(() => {
    if (
      skipShopNow &&
      state.currentStepIndex < getStepIndex(CHOOSE_RETURN_CREDIT_METHOD_STEP)
    ) {
      handleRefund();
    }
  }, [skipShopNow]);

  // shopnow chosen refund method when there is remaining credit
  const selectedRefundMethod = allowRefundToOriginalPayment
    ? "original_payment"
    : "gift_card";

  const { submitReturn, submitStatus, submitStatusReset } = useSubmitReturn();
  const checkoutUrl = submitStatus.data;
  const hasRedirectUrl = isValidHttpUrl(checkoutUrl);
  const submitting = submitStatus.loading || hasRedirectUrl;
  const submitErrorMessage = submitStatus.error;

  useEffect(() => {
    if (submittingRedirect && selectedRefundMethod && !submitting) {
      console.debug("submit return for shop now");

      let submitParams = omit(["chosenRefundMethod"], calculateParams);
      submitParams = {
        ...submitParams,
        draftReturnUuid,
        shopNow: true,
        location: omitDeep(["__typename"], state.location),
        locationId: state.locationId,
        fromAddress: isEmpty(state.fromAddress) ? undefined : state.fromAddress,
        selectedRefundMethod,
        exchangeItems,
        returnItems,
        homePickupInstructions: state.homePickupInstructions,
        requestedLabelCount: state.requestedLabelCount,
      };

      submitReturn(submitParams)
        .then(r => {
          console.debug("submit return success");
          const metrics = calculateReturnMetrics(
            order,
            "shop_now",
            state.items,
            state.lineItemIntension,
            false,
          );
          emitMetrics(metrics);
          if (config.isNthUi) {
            const abTestResult = convertFromDatadogMetrics(metrics);
            abTesting.trackResult(abTestResult);
            abTesting.trackResult(countingEvent(SUBMIT_RETURN_SUCCESS_EVENT));
          }
        })
        .catch(err => {
          console.error(err);
          actions.setShopNowRedirect(false);
        });
    }
  }, [submittingRedirect, selectedRefundMethod]);

  useEffect(() => {
    if (hasRedirectUrl) {
      window.localStorage.setItem("nv_shop_now_checkout", checkoutUrl);
      console.debug("redirect to shopify storefront:", checkoutUrl);
      redirectToCheckout(checkoutUrl);
    }
  }, [hasRedirectUrl, checkoutUrl]);

  // when user go to next step, clear the error message
  useEffect(submitStatusReset, [state.currentStepIndex]);

  const handleShopNow = () => {
    actions.setShopNowRedirect(true);
  };

  // isStoreCredit on an item means the user already opted for Shop Now, so
  // they do not need to be presented with the decision again here.
  const isAutoRedirect = useMemo(
    () => !loading && !skipShopNow && some("isStoreCredit", state.items),
    [loading, skipShopNow, state.items],
  );
  useEffect(() => {
    if (isAutoRedirect) {
      handleShopNow();
    }
  }, [isAutoRedirect]);

  const handleAddressFix = useCallback(({ fromAddress }) => {
    submitStatusReset();
    actions.setCorrectedAddress(fromAddress);
  }, []);

  useRegisterStepStatus(CHOOSE_SHOP_NOW_STEP, { skipped: skipShopNow });
  if (skipShopNow) {
    return null;
  }

  const addressError =
    queryStatus.addressError ?? getAddressError(submitStatus.errorDetail);

  if (addressError) {
    return (
      <Grid className={classes.root} container justifyContent="center">
        <Typography align="center" className={classes.text} variant="h1">
          {config.translations.mailing_address_section_title}
        </Typography>
        <MailingAddressForm
          address={mergeAddress(state.fromAddress, addressError.address)}
          onSubmit={handleAddressFix}
          disable={addressError.disableFields}
          validateZip
        />
      </Grid>
    );
  }

  if (loading) {
    return <Spinner />;
  }
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={10}>
          <Typography
            variant="h1"
            align="center"
            className={clsx(classes.title, classes.text)}>
            {config.translations.shopnow_opt_in_title}
          </Typography>
        </Grid>
        {queryErrorMessage ? (
          <Grid item xs={12}>
            <ErrorSnackbar
              message={
                <span dangerouslySetInnerHTML={{ __html: queryErrorMessage }} />
              }
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={10}>
              {showIncentive ? (
                <>
                  <Typography
                    variant="body2"
                    align="center"
                    className={clsx(classes.description, classes.text)}>
                    {config.translations.shopnow_opt_in_description}
                    {incentive &&
                      interpolate(
                        config.translations
                          .shopnow_opt_in_incentive_description,
                        { amount: incentive },
                      )}
                  </Typography>
                  <Box className={classes.incentiveContainer}>
                    <Strikethrough
                      variant="h2"
                      align="center"
                      color="textPrimary"
                      className={clsx(
                        classes.refundAmountOriginal,
                        classes.text,
                      )}>
                      {refundAmount}
                    </Strikethrough>
                    <Typography
                      variant="h1"
                      align="center"
                      className={clsx(
                        classes.refundAmount,
                        classes.text,
                        classes.incentiveText,
                      )}>
                      {creditAmount}
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  <Typography
                    variant="h2"
                    align="center"
                    color="textPrimary"
                    className={clsx(classes.refundAmount, classes.text)}>
                    {refundAmount}
                  </Typography>
                </>
              )}
              <Typography
                variant="caption"
                align="center"
                component="p"
                className={classes.notes}>
                {shopNowRefundMethod?.refundMethodDescription}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                id="choose-shop-now-submit"
                className={classes.chooseButton}
                color="primary"
                size="large"
                variant="contained"
                fullWidth
                disabled={submitting}
                onClick={handleShopNow}>
                {interpolate(config.translations.shopnow_opt_in_yes, {
                  amount: creditAmount,
                })}
              </Button>
              <Button
                id="choose-shop-now-skip"
                className={classes.skipButton}
                color="primary"
                size="large"
                fullWidth
                disabled={submitting}
                onClick={handleRefund}>
                {interpolate(config.translations.shopnow_opt_in_no, {
                  amount: refundAmount,
                })}
              </Button>
            </Grid>
            {submitErrorMessage && (
              <Grid item xs={12} sm={10}>
                <ErrorSnackbar
                  message={
                    <span
                      dangerouslySetInnerHTML={{ __html: submitErrorMessage }}
                    />
                  }
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
      {submitting && <Spinner />}
    </div>
  );
};

export default ChooseShopNow;
