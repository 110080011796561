import { defaultDataIdFromObject } from "@apollo/client/cache";

export default {
  dataIdFromObject: obj => {
    switch (obj.__typename) {
      case "ReturnMethod":
        return [obj.id, obj.carrierService].join("|");
      default:
        return defaultDataIdFromObject(obj);
    }
  },
};
