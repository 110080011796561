import React from "react";
import Image from "material-ui-image";
import ImageIcon from "@material-ui/icons/Image";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import startCase from "lodash/startCase";
import map from "lodash/map";
import PropTypes from "prop-types";

import { config } from "../../config";
import { makeRootStyles } from "../../theme/styles";
import { omit } from "lodash/fp";

const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      flexWrap: "nowrap",
    },
    thumb: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      color: "#ccc",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      overflow: "hidden",
    },
    description: {
      flexGrow: 1,
    },
    name: {},
    variantOption: {},
    quantity: {},
    returnReason: {
      ...omit(["fontFamily"], theme.typography.h4),
    },
    price: {},
    disclaimerText: {
      marginTop: theme.spacing(1),
    },
    commentContainer: {
      marginTop: theme.spacing(0.5),
    },
    comment: {
      fontStyle: "italic",
    },
  }),
  { name: "N0ReviewItem" },
);

const Item = ({
  details,
  keepTheItem,
  state,
  returnReasonTitle,
  isOriginal,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isExchange = state.type === "exchange";

  const displayPrice = isOriginal
    ? details.displayPrice
    : state.exchange.priceFormatted;

  const productTitle = isOriginal
    ? details.productTitle
    : state.exchange.productTitle;
  const name = isOriginal ? details.name : state.exchange.name;

  const variantInfo = isOriginal
    ? details.variantInfo
    : isExchange
    ? state.exchange.variantInfo
    : details.variantInfo;

  const productImageUrl = isOriginal
    ? details.productImageUrl
    : state.exchange.productImageUrl;

  console.debug("isOriginal", isOriginal, "isExchange", isExchange);
  console.debug("productTitle", productTitle, "name", name);
  console.debug("details", details);
  console.debug("state", state);

  const itemVariantInfo = map(variantInfo, (variantInfo, i) => {
    return (
      <Typography className={classes.variantOption} variant="body1" key={i}>
        {startCase(variantInfo.name)}: {variantInfo.value}
      </Typography>
    );
  });

  return (
    <Grid className={classes.root} spacing={2} container>
      <Grid item>
        {productImageUrl ? (
          <Image
            alt={variantInfo.productImageAltTxt}
            src={productImageUrl}
            disableSpinner
            className={classes.thumb}
            style={{
              paddingTop: 0,
              width: theme.spacing(14),
              height: theme.spacing(14),
            }}
            imageStyle={{ position: "relative", objectFit: "contain" }}
          />
        ) : (
          <ImageIcon fontSize="large" color="inherit" />
        )}
      </Grid>
      <Grid className={classes.description} item>
        <Typography
          className={classes.name}
          variant="h4"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: productTitle || name,
          }}
        />
        {isSmUp && (
          <React.Fragment>
            {itemVariantInfo}
            <Typography className={classes.quantity} variant="body1">
              {`${config.translations.return_review_item_quantity}: `}
              {state.quantity}
            </Typography>
          </React.Fragment>
        )}
        <Typography
          className={classes.returnReason}
          align="left"
          color="secondary">
          {returnReasonTitle}
        </Typography>
        {keepTheItem && isOriginal && (
          <Typography
            className={classes.disclaimerText}
            color="secondary"
            component="p"
            variant="h4">
            {config.translations.return_review_keep_item_disclaimer}
          </Typography>
        )}
        {!!state.comment && isOriginal && (
          <Grid container className={classes.commentContainer}>
            <Typography variant="body1">
              {`${config.translations.return_review_comments_title}: `}
              <span className={classes.comment}>{state.comment}</span>
            </Typography>
          </Grid>
        )}
      </Grid>
      {isSmUp && (
        <Grid xs={3} md={2} item>
          <Typography className={classes.price} align="right" variant="body1">
            {displayPrice}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

Item.propTypes = {
  details: PropTypes.any,
  keepTheItem: PropTypes.any,
  state: PropTypes.any,
  isOriginal: PropTypes.bool,
  returnReasonTitle: PropTypes.string,
};
Item.defaultProps = {
  returnReasonTitle: "",
  isOriginal: true,
};

export default Item;
