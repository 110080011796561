import React, { useEffect, useMemo, useCallback } from "react";
import { makeRootStyles } from "../../theme/styles";
import { Button, Grid, Typography } from "@material-ui/core";

import { get, find, isEmpty, unset, set, compose, merge } from "lodash/fp";

import { RefundMethods } from "@narvar/nth-kit-returns-headless";

import {
  REFUND_METHOD_ORIGINAL_PAYMENT,
  REFUND_METHOD_GIFT_CARD,
  RETURN_CREDIT_METHOD_SHOPNOW,
  RETURN_CREDIT_METHOD_GIFT_CARD,
  RETURN_CREDIT_METHOD_REFUND,
} from "../../../retailer-app/constants/returns";
import {
  useReturnStepIsActive,
  useReturnStepsActions,
  useReturnStepsState,
  getStepIndex,
  CHOOSE_RETURN_CREDIT_METHOD_STEP,
  REVIEW_STEP,
  useRegisterStepStatus,
} from "../../contexts/returnSteps";
import CreditMethodCard from "./CreditMethodCard";
import { buildItemsByType } from "../../modules/items";
import locale from "../../../shared/modules/lang";
import { config } from "../../config";
import ShopNowIcon from "../../components/icons/shop_now";
import ShopLaterIcon from "../../components/icons/shop_later";
import RefundIcon from "../../components/icons/refund";
import { useCalculateRefund } from "../../data/calculateRefund";
import Spinner from "../../../shared/components/Spinner";
import { interpolate } from "../../../shared/modules/template";
import { ignoreEmpty, renameKeys } from "../../../shared/modules/object";
import MailingAddressForm from "../ConfirmMailingAddress/MailingAddressForm";
import { mergeAddress } from "../../modules/address";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import { getHandledErrorMessage } from "../../../shared/modules/decodeError";
import { useTypeForm } from "../../contexts/typeForm";
import { useNthReturnsActions } from "../../contexts/nthContext";

const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    chooseButton: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: "N0ChooseReturnCreditMethod" },
);

const ChooseReturnCreditMethod = ({ orderFetchData, draftReturnUuid }) => {
  const classes = useStyles();

  const state = useReturnStepsState();
  const actions = useReturnStepsActions();
  const useNewDesign = config.isNthUi;

  const { order, returnReasonsById = {} } = orderFetchData;
  const { itemsById } = order;

  const { exchangeItems, returnItems } = useMemo(
    () => buildItemsByType(state.items, itemsById, returnReasonsById),
    [itemsById, returnReasonsById, state.items],
  );

  const selectedReturnMethod = state.selectedReturnMethod;

  const { refund, queryStatus } = useCalculateRefund({
    carrierServiceName: selectedReturnMethod.carrierService,
    draftReturnUuid,
    exchangeItems: exchangeItems.map(unset("returnItem.pictures")),
    returnItems: returnItems.map(unset("pictures")),
    shopifyOrderId: order.shopifyOrderId,
    presentmentCurrency: order.presentmentCurrency,
    shippingPrice: selectedReturnMethod.price,
    shippingMethod: selectedReturnMethod.id,
    orderNumber: state.orderNumber,
    email: state.email,
    locale,
    fromAddress: ignoreEmpty(state.fromAddress),
    csid: state.csid,
    chosenRefundMethod: null,
  });

  // calculate refund would run again in review, hide the spinner for that
  const loading = isEmpty(refund) && queryStatus.loading;
  const loaded = !isEmpty(refund);
  const { addressError } = queryStatus;
  const errorMessage = getHandledErrorMessage(
    queryStatus.error,
    config.translations.general_loading_error,
  );

  const creditMethods = useMemo(() => {
    if (!refund?.refundMethodsAvailable?.length) return [];

    const originalPayment = refund.refundMethodsAvailable.find(
      v => v.refundMethod === RETURN_CREDIT_METHOD_REFUND,
    );

    const toCreditMethodCardData = method =>
      compose(
        merge({
          title:
            method.refundMethod === "reshop"
              ? config.translations.choose_refund_method_reshop
              : method.refundMethod === RETURN_CREDIT_METHOD_REFUND
              ? config.translations.choose_refund_method_original_payment
              : interpolate(
                  config.translations.choose_refund_method_gift_card,
                  { shop_name: config.shopName },
                ),
          strikethrough:
            Math.abs(originalPayment?.refundCents + 10) <
            Math.abs(method.refundCents),
          oldPriceCents: originalPayment?.refundCents,
          oldPrice: originalPayment?.refundFormatted,
        }),
        renameKeys({
          refundMethod: "id",
          refundMethodDescription: "description",
          refundFormatted: "newPrice",
          refundCents: "newPriceCents",
        }),
      )(method);

    const ret = refund.refundMethodsAvailable
      .filter(
        v => v.refundMethod !== RETURN_CREDIT_METHOD_SHOPNOW, // shop now opt-in is handled by other step
      )
      .filter(v => v.refundCents > 0) // if consumer owes any amount, no need to show refund methods
      .map(toCreditMethodCardData)
      .sort((a, b) => {
        const toNumber = method => {
          if (method.id === RETURN_CREDIT_METHOD_REFUND) {
            return refund.refundMethodsAvailable.length + 2; // original method places in the last
          }
          if (method.id === RETURN_CREDIT_METHOD_GIFT_CARD) {
            return refund.refundMethodsAvailable.length + 1; // shopify gift card places in the second last
          }
          if (method.id === refund.chosenRefundMethod) {
            return -1; // backend chosen default method places in the first
          }
          return 0; // keep the order
        };
        return toNumber(a) - toNumber(b);
      });

    return ret;
  }, [refund]);

  useEffect(() => {
    if (creditMethods.length > 0) {
      actions.setAvailableReturnCreditMethods(creditMethods);
    }
  }, [creditMethods]);

  // TODO: conditionally render the amount: do this checking? isRefundAutomationEnabled && !isTotalNegative && !isTotal0

  // account for uneven exchange and return where no credit is refunded.
  const skipReturnCreditMethod = loaded && creditMethods.length === 0;

  const autoChoose =
    loaded &&
    creditMethods.length === 1 &&
    creditMethods[0].id === RETURN_CREDIT_METHOD_REFUND;

  const isValidSelected = useMemo(() => {
    const method = state.selectedReturnCreditMethod;
    if (!method) return false;
    return creditMethods.some(d => d.id === method.id);
  }, [state.selectedReturnCreditMethod, creditMethods]);

  const isActiveStep = useReturnStepIsActive(CHOOSE_RETURN_CREDIT_METHOD_STEP);
  const showNextBtn = !loading && !errorMessage && isActiveStep;

  useEffect(() => {
    if (
      skipReturnCreditMethod &&
      state.currentStepIndex < getStepIndex(REVIEW_STEP)
    ) {
      actions.goToStep(REVIEW_STEP);
    }
  }, [skipReturnCreditMethod, state.currentStepIndex]);

  const createSelectionHandler = useCallback(
    method => () => {
      if (get("id", method) === get("selectedReturnCreditMethod.id", state))
        return;
      actions.setReturnCreditMethod(method);
    },
    [state],
  );

  const handleAddressFix = useCallback(({ fromAddress }) => {
    actions.setCorrectedAddress(fromAddress);
  }, []);

  const handleNextStep = useCallback(() => {
    actions.goToStep(REVIEW_STEP);
  }, []);

  useEffect(() => {
    if (
      !skipReturnCreditMethod &&
      refund.chosenRefundMethod &&
      creditMethods.length > 0
    ) {
      const methodId =
        state.selectedReturnCreditMethod.id ?? refund.chosenRefundMethod;
      let method = find({ id: methodId }, creditMethods);
      if (
        !method &&
        refund.chosenRefundMethod === RETURN_CREDIT_METHOD_SHOPNOW
      ) {
        method =
          find({ id: REFUND_METHOD_ORIGINAL_PAYMENT }, creditMethods) ||
          find({ id: REFUND_METHOD_GIFT_CARD }, creditMethods);
      }

      if (method && state.selectedReturnCreditMethod.id !== method.id) {
        actions.setReturnCreditMethod(method);
      }

      if (autoChoose) {
        actions.goToStep(REVIEW_STEP);
      }
    }
  }, [
    skipReturnCreditMethod,
    autoChoose,
    state.selectedReturnCreditMethod,
    refund.chosenRefundMethod,
    creditMethods,
  ]);

  const methodIcons = carrier => {
    switch (carrier) {
      case RETURN_CREDIT_METHOD_SHOPNOW:
        return <ShopNowIcon />;
      case RETURN_CREDIT_METHOD_GIFT_CARD:
        return <ShopLaterIcon />;
      case RETURN_CREDIT_METHOD_REFUND:
        return <RefundIcon />;
      default:
        return <RefundIcon />;
    }
  };

  /***************************************************************************************************************
   * New design with `nth` components
   ***************************************************************************************************************/
  const typeForm = useTypeForm();
  const nthReturnActions = useNthReturnsActions();
  const getNthType = method => method.id.toUpperCase();
  const refundMethodOptions = useMemo(
    () =>
      creditMethods.map(method => {
        const currency = order.presentmentCurrency;
        const refundAmount = { value: method.newPriceCents, currency };
        let incentive = refundAmount?.value - method.oldPriceCents;
        incentive = incentive > 0 ? { value: incentive, currency } : undefined;
        const ret = {
          ...method,
          type: getNthType(method),
          name: method.title,
          refund: refundAmount,
          incentive,
        };
        return ret;
      }),
    [refund, creditMethods],
  );
  const selectedRefundMethod = useMemo(
    () =>
      find({ id: state.selectedReturnCreditMethod?.id }, refundMethodOptions),
    [refundMethodOptions, state.selectedReturnCreditMethod?.id],
  );
  useEffect(() => {
    nthReturnActions?.setReturnCart?.(
      set("refundMethod", selectedRefundMethod),
    );
  }, [selectedRefundMethod]);

  const isSkipStep = skipReturnCreditMethod || autoChoose;
  useRegisterStepStatus(CHOOSE_RETURN_CREDIT_METHOD_STEP, {
    skipped: isSkipStep,
  });
  if (isSkipStep) return null;

  if (addressError) {
    return (
      <Grid className={classes.root} container justifyContent="center">
        <Typography align="center" className={classes.title} variant="h1">
          {config.translations.mailing_address_section_title}
        </Typography>
        <MailingAddressForm
          address={mergeAddress(state.fromAddress, addressError.address)}
          onSubmit={handleAddressFix}
          disable={addressError.disableFields}
          validateZip
        />
      </Grid>
    );
  }

  if (useNewDesign) {
    return (
      <RefundMethods
        loading={loading}
        error={errorMessage}
        progressiveStep
        activeStep={isActiveStep}
        showIncentive
        refundOptions={refundMethodOptions}
        selectedRefundMethod={selectedRefundMethod}
        onChange={event => {
          if (event.value?.selectedRefundMethod) {
            const method = find(
              { id: event.value?.selectedRefundMethod?.id },
              refundMethodOptions,
            );
            if (method && method.id !== state.selectedReturnCreditMethod?.id) {
              actions.setReturnCreditMethod({
                ...method,
                type: method.type.toLowerCase(),
              });
            }
          }
        }}
        onBack={() => {
          actions.goBack();
          typeForm.scrollToActive();
        }}
        onSubmit={handleNextStep}
      />
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Grid
      className={classes.root}
      spacing={2}
      container
      justifyContent="center">
      <Grid xs={12} item>
        <Typography align="center" className={classes.title} variant="h1">
          {config.translations.choose_refund_method_title}
        </Typography>
        {errorMessage ? (
          <ErrorSnackbar
            message={
              <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
            }
          />
        ) : (
          <Grid container spacing={2} justifyContent="center">
            {creditMethods.map(method => (
              <Grid key={method.id} lg={4} md={4} sm={12} xs={12} item>
                <CreditMethodCard
                  selected={state.selectedReturnCreditMethod.id === method.id}
                  title={method.title}
                  subtitle={method.description}
                  strikethrough={method.strikethrough}
                  oldPrice={method.oldPrice}
                  newPrice={method.newPrice}
                  icon={methodIcons(method.id)}
                  onClick={createSelectionHandler(method)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      {showNextBtn && (
        <Grid xs={12} sm={6} md={4} item>
          <Button
            id="choose-return-credit-method-next"
            className={classes.chooseButton}
            color="primary"
            disabled={!isValidSelected}
            onClick={handleNextStep}
            size="large"
            variant="contained"
            fullWidth>
            <strong>
              {config.translations.choose_refund_method_next_button_label}
            </strong>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default ChooseReturnCreditMethod;
