import React, { useState, useEffect, useRef } from "react";
import PlusIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core/styles";

import get from "lodash/get";
import DropoffLocationLogo from "../../components/DropoffLocationLogo";
import { makeRootStyles } from "../../theme/styles";
import Map from "../../../shared/components/MaptilerMap";
import clsx from "clsx";
import { config } from "../../config";

const TRANSITION_TIMEOUT = 250;
const BORDER_WIDTH_SELECTED = 3;
const BORDER_WIDTH_UNSELECTED = 1;

const useStyles = makeRootStyles(
  theme => {
    const borderSelected = `3px solid ${theme.palette.primary.main}`;
    const borderUnselected = `${BORDER_WIDTH_UNSELECTED}px solid #ccc`;
    const paddingSelected = theme.spacing(3) - BORDER_WIDTH_SELECTED;
    const paddingUnselected = theme.spacing(3) - BORDER_WIDTH_UNSELECTED;

    return {
      root: {
        display: "flex",
        flexDirection: "column",
        "& + $root": {
          marginTop: theme.spacing(2),
        },
        backgroundColor: theme.palette.common.white,
      },
      itemSelected: {
        "& $details": {
          border: borderSelected,
          padding: paddingSelected,
        },
        "& $mapContainer": {
          marginLeft: -paddingSelected,
          marginRight: -paddingSelected,
          marginBottom: -(paddingSelected + BORDER_WIDTH_SELECTED),
        },
        "& $descriptionWithMap": {
          marginRight: -paddingSelected,
        },
      },
      details: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        // backgroundColor: theme.palette.common.white,
        border: borderUnselected,
        padding: paddingUnselected,
        boxSizing: "border-box",
      },
      detailsSelected: {
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
      },
      logoContainer: {
        textAlign: "center",
        marginRight: theme.spacing(2),
        overflowWrap: "break-word",
        maxWidth: "35%",
        [theme.breakpoints.down("xs")]: {
          maxWidth: "none",
        },
      },
      headline: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
        display: "-webkit-box",
        lineClamp: 4,
        boxOrient: "vertical",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
      description: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: theme.spacing(8),
        flexWrap: "nowrap",
        [theme.breakpoints.down("xs")]: {
          height: "auto",
          flexWrap: "wrap",
        },
      },
      locationName: {
        paddingRight: 5,
        minWidth: 95,
        [theme.breakpoints.down("xs")]: {
          minWidth: "auto",
        },
      },
      locationNameTitle: {},
      locationNameSubtitle: {},
      descriptionWithMap: {
        marginRight: -paddingUnselected,
        position: "relative",
        paddingRight: theme.spacing(6),
      },
      actionContainer: {
        position: "absolute",
        right: 0,
      },
      plusIcon: {
        transition: `transform ${TRANSITION_TIMEOUT}ms ease`,
        transform: "rotate(0deg)",
      },
      closeIcon: {
        transform: "rotate(135deg)",
      },
      mapContainer: {
        margin: `${theme.spacing(
          3,
        )}px ${-paddingUnselected}px ${-paddingUnselected}px`,
      },
      map: {
        display: "flex",
        height: theme.spacing(19),
      },
      chooseButton: {
        marginTop: theme.spacing(3),
      },
      chooseButtonMap: {
        marginTop: 0,
        borderRadius: 0,
      },
    };
  },
  { name: "N0LocationCard" },
);

function LocationCard({
  address,
  longitude,
  latitude,
  logoUrl,
  disableMap,
  onChoose,
  onClick,
  selected,
  subtitle,
  title,
  name,
  carrier,
}) {
  const classes = useStyles();
  const selectedRef = useRef(selected);
  const [mapVisible, showMap] = useState(selected);

  const handleChoose = () => {
    if (!disableMap) showMap(false);
    onChoose();
  };

  const handleToggleMap = () => showMap(visible => !visible);

  useEffect(() => {
    if (selected === selectedRef.current) return;
    selectedRef.current = selected;
    showMap(selected);
  }, [selected]);

  // when doing in store returns, fall back to the shop name
  const shopName = get(window, "nvo_config.shop_name", "");
  const displayName = name ? name : shopName;
  return (
    <div
      className={clsx(classes.root, {
        [classes.itemSelected]: selected,
      })}
      onClick={onClick}>
      <div
        className={clsx(classes.details, {
          [classes.detailsSelected]: selected,
        })}>
        <div
          className={clsx(classes.description, {
            [classes.descriptionWithMap]: !disableMap,
          })}>
          <div className={classes.logoContainer}>
            <div className={classes.logo}>
              <DropoffLocationLogo
                name={shopName}
                carrier={carrier}
                url={logoUrl}
              />
            </div>
          </div>
          <div className={classes.locationName}>
            <Typography className={classes.locationNameTitle} variant="h4">
              {displayName}
            </Typography>
            <Typography
              className={classes.locationNameSubtitle}
              variant="body1">
              {subtitle}
            </Typography>
          </div>
          <div className={classes.headline}>
            <Typography variant="body2">{title}</Typography>
            <Typography variant="body2">
              {`${[address.city, address.state]
                .filter(d => d)
                .join(", ")} ${address.zip ?? ""}`}
            </Typography>
          </div>
          {!disableMap && (
            <div className={classes.actionContainer}>
              <IconButton onClick={handleToggleMap}>
                <PlusIcon
                  color="primary"
                  className={clsx(classes.plusIcon, {
                    [classes.closeIcon]: mapVisible,
                  })}
                />
              </IconButton>
            </div>
          )}
        </div>
        <Collapse
          in={disableMap && selected}
          timeout={TRANSITION_TIMEOUT}
          mountOnEnter>
          <Button
            onClick={handleChoose}
            className={clsx(classes.chooseButton, classes.chooseButtonNoMap)}
            color="primary"
            size="large"
            variant="contained"
            fullWidth>
            <strong>
              {config.translations.choose_method_substep_choose_location_button}
            </strong>
          </Button>
        </Collapse>
        <div className={classes.mapContainer}>
          <Collapse
            in={!disableMap && mapVisible}
            timeout={TRANSITION_TIMEOUT}
            mountOnEnter>
            <div className={classes.map}>
              <Map
                address={address}
                longitude={longitude}
                latitude={latitude}
              />
            </div>
            <Button
              onClick={handleChoose}
              className={clsx(classes.chooseButton, classes.chooseButtonMap)}
              color="primary"
              size="large"
              variant="contained"
              fullWidth>
              <strong>
                {
                  config.translations
                    .choose_method_substep_choose_location_button
                }
              </strong>
            </Button>
          </Collapse>
        </div>
      </div>
    </div>
  );
}

export default LocationCard;
