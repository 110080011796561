import { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import { getAddressError } from "../../shared/modules/decodeError";

const QUERY = gql`
  query calculateRefund(
    $carrierServiceName: String
    $draftReturnUuid: String
    $returnItems: [ReturnCalculationInput!]!
    $exchangeItems: [ExchangeItemInput!]!
    $shopifyOrderId: String!
    $presentmentCurrency: String!
    $shippingPrice: Float
    $shippingMethod: String
    $orderNumber: String!
    $email: String!
    $locale: String
    $chosenRefundMethod: String
    $fromAddress: FromAddressInput
    $csid: String
  ) {
    calculateRefund(
      carrierServiceName: $carrierServiceName
      draftReturnUuid: $draftReturnUuid
      returnItems: $returnItems
      exchangeItems: $exchangeItems
      shopifyOrderId: $shopifyOrderId
      presentmentCurrency: $presentmentCurrency
      shippingPrice: $shippingPrice
      shippingMethod: $shippingMethod
      orderNumber: $orderNumber
      email: $email
      locale: $locale
      chosenRefundMethod: $chosenRefundMethod
      fromAddress: $fromAddress
      csid: $csid
    ) {
      currency
      subtotalRefund
      subtotalRefundCents
      totalRefund
      totalRefundCents
      totalTax
      totalTaxDisplayAmt
      taxesIncluded
      priorRefundsDisplayAmt
      refundLineItems {
        discountedPrice
        lineItemId
        locationId
        price
        quantity
        restockType
        subtotal
        totalCartDiscountAmount
        totalTax
      }
      originalShippingRefundedDisplayAmt
      refundMethodsAvailable {
        refundMethod
        refundMethodDescription
        refundCents
        refundFormatted
      }
      chosenRefundMethod
      paymentClientSecret
      customerPmtId
      preChargeFeeAmountCents
      returnMethodDisplayPrice
      exchangeShippingFeeDisplayPrice
      exchangeShippingFeeDisplayName
      feeBreakdown {
        description
        formattedFee
      }
      shopNowAvailable

      originalPaymentTotalRefund
      originalPaymentTotalRefundCents
      shopNowTotalRefund
      shopNowTotalRefundCents
      giftCardTotalRefund
      giftCardTotalRefundCents
    }
  }
`;

export function useCalculateRefund(variables) {
  const saved = useQuery(QUERY, { variables });
  const addressError = getAddressError(saved.error);

  const queryStatus = {
    loading: saved.loading,
    error: saved.error,
    addressError,
  };

  const { calculateRefund: refund = {} } = saved.data || {};

  return useMemo(
    () => ({
      queryStatus,
      refund,
      refetch: saved.refetch,
    }),
    [queryStatus, refund, saved],
  );
}
