import React from "react";
import PropTypes from "prop-types";

import { makeRootStyles } from "../theme/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeRootStyles(
  theme => ({
    strikethrough: {
      position: "relative",

      "&::before": {
        position: "absolute",
        content: '""',
        left: 0,
        top: "45%",
        right: 0,
        borderTop: "2px solid",
        borderColor: "inherit",
        "-webkit-transform": "skewY(-10deg)",
        "-moz-transform": "skewY(-10deg)",
        transform: "skewY(-10deg)",
      },
    },
  }),
  { name: "N0Strikethrough" },
);

function Strikethrough({ component, children, ...props }) {
  const classes = useStyles();
  const Component = component;

  return (
    <Component {...props}>
      <span className={classes.strikethrough}>{children}</span>
    </Component>
  );
}

Strikethrough.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
};

Strikethrough.defaultProps = {
  color: "default",
  component: Typography,
};

export default Strikethrough;
