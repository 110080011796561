import React, { useState, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Typography } from "@material-ui/core";
import { isEmpty, noop, pick, set } from "lodash/fp";

import { config } from "../../config";
import { makeRootStyles } from "../../theme/styles";
import { assertAnyFieldBy, trimObject } from "../../../shared/modules/object";
import { controlGridSize } from "../../../shared/modules/mui-utils";

const useStyles = makeRootStyles(
  theme => ({
    input: {
      backgroundColor: theme.palette.common.white,
    },
    submit: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: "ContactInformationForm" },
);

function ContactInformationForm({
  size,
  contact: initialContact,
  onChange,
  onSubmit,
}) {
  const classes = useStyles();
  const [draft, setDraft] = useState(initialContact || {});

  const validate = useCallback(contact => {
    const data = trimObject(contact);
    const requiredFields = ["email", "phone"];
    if (assertAnyFieldBy(isEmpty, pick(requiredFields, data))) return false;
    return true;
  }, []);

  const isValid = useMemo(() => validate(draft), [draft]);

  useEffect(() => {
    onChange({ contact: draft, isValid });
  }, [draft]);

  const handleChange = useCallback(event => {
    const key = event.target.name;
    const value = event.target.value;
    setDraft(set(key, value));
  }, []);

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      if (isValid) {
        onSubmit({ contact: draft, isValid });
      }
    },
    [onSubmit, draft],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item {...controlGridSize({ md: 6, xs: 12 }, size)}>
          <TextField
            className={classes.input}
            margin="none"
            variant="outlined"
            color="primary"
            fullWidth
            label={config.translations.mailing_address_form_email}
            name="email"
            value={draft?.email || ""}
            disabled
            onChange={handleChange}
          />
        </Grid>
        <Grid item {...controlGridSize({ md: 6, xs: 12 }, size)}>
          <TextField
            className={classes.input}
            margin="none"
            variant="outlined"
            color="primary"
            fullWidth
            label={config.translations.mailing_address_form_phone}
            name="phone"
            value={draft?.phone || ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </form>
  );
}

ContactInformationForm.propTypes = {
  size: PropTypes.oneOf(["large", "medium", "small"]),
  contact: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

ContactInformationForm.defaultProps = {
  size: "medium",
  onChange: noop,
  onSubmit: noop,
};

export default ContactInformationForm;
