import React from "react";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core/styles";

import { config } from "../../config";
import { makeRootStyles } from "../../theme/styles";

const BORDER_WIDTH_SELECTED = 3;
const BORDER_WIDTH_UNSELECTED = 1;

const useStyles = makeRootStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "center",
      height: "100%",
      cursor: "pointer",
      backgroundColor: theme.palette.common.white,
      border: `${BORDER_WIDTH_UNSELECTED}px solid #ccc`,
      [theme.breakpoints.ie11()]: { height: theme.spacing(32) },
      [theme.breakpoints.edge()]: { height: theme.spacing(32) },
    },
    itemSelected: {
      border: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
    },
    details: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "nowrap",
      padding: theme.spacing(3) - BORDER_WIDTH_UNSELECTED,
    },
    detailsSelected: {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      padding: theme.spacing(3) - BORDER_WIDTH_SELECTED,
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(1),
      },
    },
    icon: {
      width: theme.spacing(12),
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
        maxWidth: theme.spacing(24),
        paddingRight: 0,
      },
    },
    description: {
      textAlign: "center",
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    qr: {
      marginBottom: theme.spacing(1),
    },
    price: {
      marginBottom: theme.spacing(1),
    },
    subtitle: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  }),
  { name: "N0MethodCard" },
);

// @deprecated the new UI (`nth_ui`) uses `PudoRaw` instead
function MethodCard({ icon, selected, title, subtitle, onClick, price, qr }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.itemSelected]: selected,
      })}
      onClick={onClick}>
      <Grid
        className={clsx(classes.details, {
          [classes.detailsSelected]: selected,
        })}
        container>
        <Grid xs={12} sm={12} md={4} item className={classes.iconContainer}>
          <div className={classes.icon}>{icon}</div>
        </Grid>
        <Grid xs={12} sm={12} md={8} item className={classes.description}>
          <Typography
            className={classes.title}
            align="center"
            variant="h4"
            gutterBottom
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {qr && (
            <Typography
              className={classes.qr}
              align="center"
              variant="body1"
              gutterBottom
              dangerouslySetInnerHTML={{ __html: qr }}
            />
          )}
          {!config.hideReturnMethodPrice && (
            <Typography
              color="secondary"
              className={classes.price}
              component="p"
              variant="h4"
              align="center"
              dangerouslySetInnerHTML={{ __html: price }}
            />
          )}
          <Typography
            className={classes.subtitle}
            component="p"
            variant="body2"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default MethodCard;
