import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import isObject from "lodash/isObject";

import { Autocomplete } from "../../../shared/components/form";
import { makeRootStyles } from "../../theme/styles";
import { config } from "../../config";

const useStyles = makeRootStyles(
  theme => ({
    container: {
      paddingBottom: theme.spacing(2),
    },
    input: {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
    },
    subtitle: {
      height: theme.spacing(3),
    },
    formElement: {
      textAlign: "center",
    },
    formTextContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    countryRoot: {
      [theme.breakpoints.up("sm")]: {
        minWidth: 200,
      },
    },
    countryInputEndAdornment: {
      marginTop: -10,
      marginRight: -12,
    },
    countryInput: {
      "&:first-child": {
        padding: 0,
      },
    },
    countryInputRoot: {
      backgroundColor: theme.palette.common.white,
      '&[class*="MuiOutlinedInput-root"]': {
        padding: theme.spacing(1),
        paddingRight: theme.spacing(5),
      },
    },
  }),
  { name: "N0ZipForm" },
);

const ZipForm = ({
  error,
  initialCountryCode,
  initialZip,
  isOpen,
  onSearch,
  toggleForm,
}) => {
  const classes = useStyles();

  const [zipcode, updateZipcode] = useState(initialZip);
  const [countryCode, updateCountryCode] = useState(initialCountryCode);
  const countryName = config.countryNamesByCode[countryCode];

  const handleSubmit = event => {
    event.preventDefault();
    onSearch({ zipcode, countryCode });
  };

  if (isOpen || error) {
    return (
      <Grid
        alignItems="center"
        className={classes.container}
        component="form"
        justifyContent="center"
        onSubmit={handleSubmit}
        spacing={1}
        container>
        <Grid xs={12} sm={12} md="auto" item>
          <div className={clsx(classes.formElement, classes.formTextContainer)}>
            <Typography variant="body1" align="center">
              {
                config.translations
                  .choose_method_substep_in_store_form_find_stores_near
              }
            </Typography>
          </div>
        </Grid>
        <Grid xs={8} sm="auto" item>
          <Autocomplete
            autoHighlight
            disableClearable
            classes={{
              endAdornment: classes.countryInputEndAdornment,
              input: classes.countryInput,
              inputRoot: classes.countryInputRoot,
            }}
            className={classes.countryRoot}
            getOptionLabel={countryCode => {
              if (isObject(countryCode)) return countryCode.country;
              return countryCode;
            }}
            onChange={country =>
              console.debug(country) || updateCountryCode(country.country_code)
            }
            options={config.countryCodes}
            placeholder={config.translations.mailing_address_form_country}
            value={countryName}
          />
        </Grid>
        <Grid xs={4} sm="auto" item>
          <OutlinedInput
            autoFocus
            fullWidth
            autoComplete="postal-code"
            className={classes.formElement}
            classes={{ input: classes.input }}
            color="primary"
            inputProps={{ style: { textAlign: "center" } }}
            onChange={({ target: { value } }) => updateZipcode(value)}
            placeholder={config.translations.zip_code}
            value={zipcode}
          />
        </Grid>
        <Grid xs={12} sm="auto" item>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className={classes.formElement}
            fullWidth>
            {config.translations.search}
          </Button>
        </Grid>
        {error ? (
          <Grid xs={12} item>
            {error}
          </Grid>
        ) : (
          <Grid xs={12} sm="auto" item>
            <Button
              variant="text"
              color="primary"
              className={classes.formElement}
              onClick={toggleForm}
              fullWidth>
              {config.translations.cancel}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid className={classes.container} spacing={1} container>
      <Grid xs={12} item>
        <Typography className={classes.subtitle} variant="body1" align="center">
          {`${config.translations.choose_method_substep_in_store_locations_near} `}
          <Link onClick={toggleForm}>{`${zipcode}, ${countryName}`}</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ZipForm;
