import React, { useEffect, useMemo } from "react";

import {
  useReturnStepsState,
  useReturnStepsActions,
  CHOOSE_SHOP_NOW_STEP,
  getStepIndex,
  CONFIRM_MAILING_ADDRESS_STEP,
  useRegisterStepStatus,
} from "../../contexts/returnSteps";

import MailingAddress from "./MailingAddress";
import { mergeAddress } from "../../modules/address";
import { METHOD_XPO } from "../../../retailer-app/constants/returns";

function ConfirmMailingAddress({ orderFetchData }) {
  const state = useReturnStepsState();
  const actions = useReturnStepsActions();

  const isGuestReturn = state.guestReturn;
  const isHomePickup = state.selectedReturnMethod.id === METHOD_XPO;
  const isSkipStep = !isGuestReturn && !isHomePickup;
  const type = isHomePickup ? "homePickup" : isGuestReturn ? "guestReturn" : ""; // it is possible that it was both home pickup and guess return

  useEffect(() => {
    if (
      isSkipStep &&
      state.currentStepIndex < getStepIndex(CHOOSE_SHOP_NOW_STEP)
    ) {
      actions.goToStep(CHOOSE_SHOP_NOW_STEP);
    }
  }, [
    state.guestReturn,
    state.items,
    state.selectedReturnMethod,
    state.locationId,
    state.currentStepIndex,
  ]);

  const defaultAddress = useMemo(
    () =>
      isGuestReturn
        ? {
            countryCode: orderFetchData.order.fromCountryCode,
            province: orderFetchData.order.fromProvince,
            provinceCode: orderFetchData.order.fromProvinceCode,
          }
        : {
            firstName: orderFetchData.order.fromFirstName,
            lastName: orderFetchData.order.fromLastName,
            address1: orderFetchData.order.fromAddress1,
            address2: orderFetchData.order.fromAddress2,
            city: orderFetchData.order.fromCity,
            zip: orderFetchData.order.fromZip,
            province: orderFetchData.order.fromProvince,
            provinceCode: orderFetchData.order.fromProvinceCode,
            countryCode: orderFetchData.order.fromCountryCode,
          },
    [orderFetchData, isHomePickup],
  );

  const contact = useMemo(
    () => ({
      email: state.decodedGuestEmail,
      phone: state.fromAddress.phone,
    }),
    [state.decodedGuestEmail, state.fromAddress.phone],
  );

  useRegisterStepStatus(CONFIRM_MAILING_ADDRESS_STEP, { skipped: isSkipStep });
  if (isSkipStep) {
    return null;
  }

  return (
    <MailingAddress
      type={type}
      address={mergeAddress(defaultAddress, state.fromAddress)}
      contact={contact}
      notes={state.homePickupInstructions}
    />
  );
}
export default ConfirmMailingAddress;
