import React from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { config } from "../../config";
import { makeRootStyles } from "../../theme/styles";

const { shouldHideNarvarSeamlessExchangeValue } = config;

const useStyles = makeRootStyles(
  theme => ({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    lineItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      borderTop: "1px solid #c5c5c5",
    },
    returnMethod: {},
    refundMethod: {},
    total: {
      "& .MuiTypograph-root": {
        fontWeight: "bold",
      },
    },
    captionText: {
      marginTop: theme.spacing(2),
      color: "#A0A0A0",
    },
  }),
  { name: "N0ReviewRefund" },
);

const Refund = ({
  taxesIncluded,
  totalDisplayPrice,
  totalRefundCents,
  totalDisplayTax,
  priorRefundsDisplayAmt,
  subtotalDisplayPrice,
  purchaseShippingDisplayPrice,
  returnMethodDisplayName,
  exchangeShippingFeeDisplayPrice,
  exchangeShippingFeeDisplayName,
  isCharge,
  isPreCharge,
  feeBreakdown,
  refundMethod,
}) => {
  const classes = useStyles();

  function translatedFee(fee) {
    if (config.translations[fee]) return config.translations[fee];
    if (fee === "Shipping Fee")
      return config.translations.return_review_shipping_fee;
    return fee;
  }

  const title = (() => {
    if (isPreCharge) {
      return "Refund Total (pre charged)";
    }

    if (isCharge) {
      return config.translations.return_review_charge_total;
    } else {
      return config.translations.return_review_estimated_refund + "*";
    }
  })();

  return (
    <Grid className={classes.root} container>
      {!config.onlyShowEstimatedRefund && (
        <>
          <Grid
            className={classes.lineItem}
            container
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="body1">
              {config.translations.return_review_subtotal}
            </Typography>
            <Typography variant="body1">
              {shouldHideNarvarSeamlessExchangeValue && totalRefundCents == 0
                ? "-"
                : subtotalDisplayPrice}
            </Typography>
          </Grid>
          <Grid
            className={classes.lineItem}
            container
            justifyContent="space-between"
            alignItems="flex-start">
            <div>
              <Typography variant="body1">
                {taxesIncluded
                  ? config.translations.return_review_taxes_included
                  : config.translations.return_review_total_tax}
              </Typography>
            </div>
            <Typography variant="body1">{totalDisplayTax}</Typography>
          </Grid>
          {priorRefundsDisplayAmt && (
            <Grid
              className={classes.lineItem}
              container
              justifyContent="space-between"
              alignItems="flex-start">
              <div>
                <Typography variant="body1">
                  {config.translations.return_review_prior_refunds}
                </Typography>
              </div>
              <Typography variant="body1">{priorRefundsDisplayAmt}</Typography>
            </Grid>
          )}
          {purchaseShippingDisplayPrice && (
            <Grid
              className={classes.lineItem}
              container
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="body1">
                {config.translations.return_review_original_purchase_shipping}
              </Typography>
              <Typography variant="body1">
                {purchaseShippingDisplayPrice}
              </Typography>
            </Grid>
          )}
          <Grid
            className={clsx(classes.lineItem, classes.returnMethod)}
            container
            justifyContent="space-between"
            alignItems="center">
            <Typography variant="body1">
              {config.translations.return_review_return_method}:{" "}
              {returnMethodDisplayName}
            </Typography>
          </Grid>
          {feeBreakdown &&
            feeBreakdown
              .filter(
                i =>
                  !shouldHideNarvarSeamlessExchangeValue ||
                  (i.description != "NARVAR_SEAMLESS_EXCHANGE_VALUE" &&
                    i.description != "NARVAR_SEAMLESS_EXCHANGE_VALUE_TAX"),
              )
              .map((fee, i) => {
                return (
                  <Grid
                    className={classes.lineItem}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    key={i}>
                    <Typography variant="body1">
                      {translatedFee(fee.description)}
                    </Typography>
                    <Typography variant="body1">{fee.formattedFee}</Typography>
                  </Grid>
                );
              })}
          {exchangeShippingFeeDisplayName && (
            <Grid
              className={classes.lineItem}
              container
              justifyContent="space-between"
              alignItems="center">
              <Typography variant="body1">
                {exchangeShippingFeeDisplayName}
              </Typography>
              <Typography variant="body1">
                {isCharge &&
                  parseFloat(exchangeShippingFeeDisplayPrice.substring(1)) !=
                    0 &&
                  "-"}
                {exchangeShippingFeeDisplayPrice}
              </Typography>
            </Grid>
          )}
        </>
      )}
      <Grid
        className={clsx(classes.lineItem, classes.total)}
        container
        justifyContent="space-between"
        alignItems="flex-start">
        <Typography variant="h4">{title}</Typography>
        <Typography variant="h4">{totalDisplayPrice}</Typography>
      </Grid>
      <Grid container className={classes.refundMethod}>
        <Typography variant="body1">{refundMethod}</Typography>
      </Grid>
      <Typography className={classes.captionText} variant="caption">
        *{config.translations.return_review_disclaimer}
      </Typography>
    </Grid>
  );
};

export default Refund;
