import React, { useState, useMemo, useEffect } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import get from "lodash/get";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { makeRootStyles } from "../../theme/styles";
import Spinner from "../../../shared/components/Spinner";
import { config } from "../../config";
import { useTheme } from "@material-ui/styles";
import { useStripeComponents } from "../../contexts/StripeProvider";

const useStyles = makeRootStyles(
  theme => ({
    stripeElement: {
      padding: "10px 20px",
      backgroundColor: "white",
      transition: "box-shadow 150ms ease",
      marginBottom: theme.spacing(2),
      width: "100%",
      border: "1px solid #e8e8fb",
      pointerEvents: props => (props.disabled ? "none" : "auto"),
    },
    form: {
      width: "100%",
    },
    submitContainer: {
      marginTop: theme.spacing(4),
      width: "100%",
      borderRadius: 4,
      border: "1px solid #e8e8fb",
      padding: theme.spacing(4),
    },
    buttonContainer: {
      width: "100%",
    },
    submitButton: {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
    paymentText: {
      marginBottom: theme.spacing(2),
    },
    errorText: {
      color: theme.palette.error.main,
    },
  }),
  { name: "N0StripeCheckoutForm" },
);

export const confirmCardPayment = async ({ stripe, elements, secret }) => {
  if (!stripe || !elements) return;

  return stripe
    .confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          // name: "Jenny Rosen",
        },
      },
      setup_future_usage: "off_session",
    })
    .then(result => {
      if (result.error) {
        throw result.error;
      } else {
        if (result.paymentIntent.status === "requires_capture") {
          return { paymentId: result.paymentIntent.id };
        }
      }
    });
};

function CheckoutForm({
  secret,
  onSuccess,
  hideSubmit,
  submitLoading,
  backendPaymentFailureError = null,
}) {
  const theme = useTheme();
  const { stripe, elements } = useStripeComponents();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(backendPaymentFailureError);
  const classes = useStyles({ disabled: submitLoading || loading });

  const cardElementOptions = useMemo(
    () => ({
      style: {
        base: {
          fontFamily: theme.typography.body1.fontFamily,
          fontSize: `${theme.typography.body1.fontSize}px`,
          color: theme.palette.text.primary,
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: theme.palette.text.hint,
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    }),
    [theme],
  );

  useEffect(() => setError(backendPaymentFailureError), [
    backendPaymentFailureError,
  ]);

  const handleChange = ({ error }) => {
    console.error("handleChange error", error);
    setError(get(error, "message"));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!stripe || !elements) return;

    setLoading(true);
    setError();

    confirmCardPayment({ stripe, elements, secret })
      .then(result => {
        setLoading(false);
        onSuccess(result);
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      })
      .catch(error => {
        setLoading(false);
        setError(error.message);
      });
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid
        className={classes.submitContainer}
        container
        direction="column"
        justifyContent="center"
        alignItems="center">
        <Typography align="center" variant="h4" className={classes.paymentText}>
          {config.translations.return_review_payment_title}
        </Typography>
        <CardElement
          className={classes.stripeElement}
          options={cardElementOptions}
          onChange={handleChange}
        />

        {!hideSubmit && (
          <Grid xs={12} sm={6} md={4} item className={classes.buttonContainer}>
            <Button
              className={classes.submitButton}
              color="primary"
              disabled={submitLoading || loading}
              onClick={handleSubmit}
              size="large"
              variant="contained"
              type="submit"
              fullWidth>
              <strong>{config.translations.return_review_submit_return}</strong>
            </Button>
          </Grid>
        )}
        {!hideSubmit && (submitLoading || loading) && (
          <Grid xs={12} item>
            <Spinner />
          </Grid>
        )}
        {error && (
          <Grid xs={12} item>
            <Typography
              align="center"
              variant="body1"
              className={classes.errorText}>
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default CheckoutForm;
