import React from "react";
import PropTypes from "prop-types";

// Importing loadStripe without side effects
// Stripe.js will not be loaded until `loadStripe` is called
// https://github.com/stripe/stripe-js#importing-loadstripe-without-side-effects
import { loadStripe } from "@stripe/stripe-js/pure";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";

import { config } from "../config";
import { featureFlaggedHook } from "../../shared/modules/hooks";
import { memoObjectByKeyValues } from "../../shared/modules/object";

const stripePromise = config.isStripeEnabled
  ? loadStripe(config.stripePubKey, { stripeAccount: config.stripeAccount })
  : null;

const StripeProvider = ({ stripe, children }) => {
  return <Elements stripe={stripe}>{children}</Elements>;
};

StripeProvider.propTypes = {
  stripe: PropTypes.object, // stripe promise object
  children: PropTypes.node.isRequired,
};

StripeProvider.defaultProps = {
  stripe: stripePromise,
};

export default StripeProvider;

export const useStripeComponents = featureFlaggedHook(
  config.isStripeEnabled,
  {},
)(() => {
  const stripe = useStripe();
  const elements = useElements();

  return memoObjectByKeyValues({
    stripe,
    elements,
  });
});
