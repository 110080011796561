import React from "react";

function Refund(props) {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M19.4956 26.2056C16.6235 26.2056 14.2109 24.2027 14.2109 21.5989C14.2109 18.9951 16.5086 16.9922 19.4956 16.9922"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.8294 16.5329H19.4956"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.8294 21.1384H19.4956"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4956 26.207H62.3859C64.434 26.207 66.0002 27.5379 66.0002 29.2782V55.3828C66.0002 57.1231 64.434 58.454 62.3859 58.454H39.3745"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9509 59.3765H17.9582C15.8348 59.3765 14.2109 58.0124 14.2109 56.2286V21.6016"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.416 55.1091L33.764 50.1602L38.9958 54.8985"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.7639 64.5857V50.1602"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Refund;
