import { useCallback, useMemo, useState } from "react";
import { useApolloClient, gql } from "@apollo/client";

function buildCheckoutQuery(checkoutCode, cancel = false) {
  // prettier-ignore
  return gql`
  query checkShopNowExpiration {
    checkShopNowExpiration(code: "${checkoutCode}", cancel: ${cancel}) {
      expired
      formattedAmount
    }
  }
`;
}

export function useShopNowExpiration() {
  const [called, setCalled] = useState(false);
  const [calledCancel, setCalledCancel] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const state = useMemo(() => {
    return {
      called,
      calledCancel,
      error,
      loading,
      data,
    };
  }, [called, calledCancel, data, error, loading]);

  const checkShopNow = useCallback(
    function checkShopNow(checkoutCode, cancel) {
      async function checkShopNowAsync(checkoutCode, cancel) {
        if (!checkoutCode) return;

        try {
          setCalled(true);
          setLoading(true);
          if (cancel) {
            setCalledCancel(true);
          }

          const { error, data } = await client.query({
            query: buildCheckoutQuery(checkoutCode, cancel),
            fetchPolicy: "network-only",
          });

          if (error) {
            setError(error);
          } else {
            setData(data);
            setError(null);
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
          setCalled(false);
          setCalledCancel(false);
        }
      }

      checkShopNowAsync(checkoutCode, cancel);
    },
    [client],
  );

  return useMemo(() => ({ checkShopNow, state }), [checkShopNow, state]);
}
