import React from "react";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core/styles";

import { palette } from "../../../shared/theme/create-theme";
import { makeRootStyles } from "../../theme/styles";
import { config } from "../../config";
import { interpolate } from "../../../shared/modules/template";
import Strikethrough from "../../components/Strikethrough";

const BORDER_WIDTH_SELECTED = 3;
const BORDER_WIDTH_UNSELECTED = 1;

const useStyles = makeRootStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "center",
      height: "100%",
      cursor: "pointer",
      backgroundColor: theme.palette.common.white,
      border: `${BORDER_WIDTH_UNSELECTED}px solid #ccc`,
      [theme.breakpoints.ie11()]: { height: theme.spacing(32) },
      [theme.breakpoints.edge()]: { height: theme.spacing(32) },
    },
    itemSelected: {
      border: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
    },
    details: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "nowrap",
      padding: theme.spacing(3) - BORDER_WIDTH_UNSELECTED,
    },
    detailsSelected: {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
      padding: theme.spacing(3) - BORDER_WIDTH_SELECTED,
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        marginBottom: theme.spacing(1),
      },
    },
    icon: {
      width: theme.spacing(12),
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
        maxWidth: theme.spacing(24),
        paddingRight: 0,
      },
    },
    description: {
      textAlign: "center",
    },
    title: {},
    subtitle: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    strikethrough: {
      marginRight: theme.spacing(1),
    },
    incentiveContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    incentiveAmount: {
      color: palette.red,
    },
  }),
  { name: "N0CreditOptionCard" },
);

function CreditOptionCard({
  icon,
  selected,
  title,
  subtitle,
  onClick,
  strikethrough,
  oldPrice,
  newPrice,
}) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, {
        [classes.itemSelected]: selected,
      })}
      onClick={onClick}>
      <Grid
        className={clsx(classes.details, {
          [classes.detailsSelected]: selected,
        })}
        container>
        <Grid item xs={12} sm={12} md={4} className={classes.iconContainer}>
          <div className={classes.icon}>{icon}</div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} className={classes.description}>
          <Typography
            className={classes.title}
            align="center"
            variant="h4"
            gutterBottom>
            {title}
          </Typography>
          {newPrice && (
            <div className={classes.incentiveContainer}>
              {strikethrough && oldPrice && (
                <Strikethrough
                  className={classes.strikethrough}
                  align="center"
                  variant="h4"
                  color="textPrimary"
                  gutterBottom>
                  {oldPrice}
                </Strikethrough>
              )}
              <Typography
                className={clsx({
                  [classes.incentiveAmount]: strikethrough && oldPrice,
                })}
                align="center"
                variant={strikethrough && oldPrice ? "h3" : "h4"}
                color="secondary"
                gutterBottom>
                {newPrice}
              </Typography>
            </div>
          )}
          <Typography
            className={classes.subtitle}
            component="p"
            variant="body2">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreditOptionCard;
