import React from "react";

function ConfirmationCheckIcon({ color, className }) {
  return (
    <svg
      className={className}
      width="80px"
      height="80px"
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink">
      <defs>
        <path
          d="M40,0 C62.0468148,0 80,17.9531852 80,40 C80,62.0468148 62.0468148,80 40,80 C17.9531852,80 0,62.0468148 0,40 C0,17.9531852 17.9531852,0 40,0 Z M40,5 C20.6973333,5 5,20.6973333 5,40 C5,59.3026667 20.6973333,75 40,75 C59.3026667,75 75,59.3026667 75,40 C75,20.6973333 59.3026667,5 40,5 Z M59.4999998,24 L62.9999999,27.5 L33,57.4999999 L19,43.5000001 L22.5,40 L33,50.5 L59.4999998,24 Z"
          id="path-1"></path>
      </defs>
      <g
        id="Self-Service-Returns---NV0"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="m-NV0-Simple-Returns---Consumer---Confirmation"
          transform="translate(-148.000000, -3344.000000)">
          <g
            id="bit-/-icon-/-check"
            transform="translate(148.000000, 3344.000000)">
            <mask id="mask-2" fill="white">
              <use href="#path-1"></use>
            </mask>
            <use
              id="check-thick"
              fill={color}
              fillRule="evenodd"
              href="#path-1"></use>
            <rect
              id="swatch"
              fill={color}
              fillRule="evenodd"
              mask="url(#mask-2)"
              x="0"
              y="0"
              width="80"
              height="80"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ConfirmationCheckIcon;
