import React from "react";
import get from "lodash/get";

import {
  CHOOSE_METHOD_SUBSTEP,
  useRegisterStepStatus,
  useReturnStepsActions,
  useReturnStepsState,
} from "../../contexts/returnSteps";

import {
  METHOD_IN_STORE,
  METHOD_KEEP_THE_ITEM,
} from "../../../retailer-app/constants/returns";

import ChooseLocation from "./ChooseLocation";
import { config } from "../../config";
import {
  normalizeAddressKeys,
  mergeAddress,
  patchZipCode,
} from "../../modules/address";

function ChooseMethodSubstep({ orderFetchData }) {
  const state = useReturnStepsState();
  const actions = useReturnStepsActions();
  const { order } = orderFetchData;
  const returnMethodId = get(state, "selectedReturnMethod.id");
  const returnMethodService = get(state, "selectedReturnMethod.carrierService");
  const qr = get(state, "selectedReturnMethod.qr");
  // not all carriers support concierge. in store always displays locations
  const shouldDisplayLocations = get(
    state,
    "selectedReturnMethod.shouldDisplayLocations",
  );

  const initialAddress = patchZipCode(
    mergeAddress(
      normalizeAddressKeys(order, "billing"),
      normalizeAddressKeys(order, "from"),
      state.fromAddress,
    ),
  );
  const initialCountryCode = initialAddress.countryCode;
  const initialZip = initialAddress.zip;

  const useNewDesign = config.isNthUi;
  const isSkipStep =
    useNewDesign || // location selection is handled in choose method step
    returnMethodId === METHOD_KEEP_THE_ITEM ||
    !shouldDisplayLocations;

  useRegisterStepStatus(CHOOSE_METHOD_SUBSTEP, { skipped: isSkipStep });
  if (isSkipStep) return null;

  if (returnMethodId === METHOD_IN_STORE) {
    return (
      <ChooseLocation
        countryCode={initialCountryCode}
        zipcode={initialZip}
        allowProceedWithoutLocation={false}
        carrier="in_store"
      />
    );
  }

  if (shouldDisplayLocations) {
    // UPS Mail Innovations uses USPS drop-off locations for fulfillment.
    const carrier =
      returnMethodId === "ups" && returnMethodService === "UPS Mail Innovations"
        ? "usps_returns"
        : returnMethodId;

    return (
      <ChooseLocation
        countryCode={initialCountryCode}
        zipcode={initialZip}
        carrier={carrier}
        allowProceedWithoutLocation={true}
        qr={qr}
      />
    );
  }

  return null;
}

export default ChooseMethodSubstep;
