import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={72}
      height={65}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M61.466 19.39c0 4.092-3.314 7.41-7.4 7.41-4.088 0-7.4-3.318-7.4-7.41 0-4.094 3.312-7.412 7.4-7.412 4.086 0 7.4 3.318 7.4 7.411z"
        fill="#E7E7E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.034 19.39c0 4.435 3.603 8.043 8.031 8.043 4.43 0 8.033-3.608 8.033-8.043 0-4.437-3.603-8.045-8.033-8.045-4.428 0-8.031 3.608-8.031 8.045zm1.264 0c0-3.738 3.036-6.779 6.767-6.779 3.732 0 6.768 3.041 6.768 6.779 0 3.737-3.036 6.777-6.768 6.777-3.731 0-6.767-3.04-6.767-6.777z"
        fill="#231F20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.034 19.39c0 4.435 3.603 8.043 8.031 8.043 4.43 0 8.033-3.608 8.033-8.043 0-4.437-3.603-8.045-8.033-8.045-4.428 0-8.031 3.608-8.031 8.045zm1.264 0c0-3.738 3.036-6.779 6.767-6.779 3.732 0 6.768 3.041 6.768 6.779 0 3.737-3.036 6.777-6.768 6.777-3.731 0-6.767-3.04-6.767-6.777z"
        fill="#231F20"
      />
      <path
        d="M64.225 46.336v-9.115c0-3.793-3.1-6.898-6.888-6.898h-6.543c-3.788 0-6.888 3.105-6.888 6.898v9.115h20.319zM39.725 40.931L35.53 25.883a2.287 2.287 0 00-2.202-1.675H5.622a2.29 2.29 0 00-2.203 2.907l4.197 15.048a2.286 2.286 0 002.202 1.675h5.886l2.068-5.909a2.098 2.098 0 011.98-1.406h2.972a1.802 1.802 0 011.699 2.4l-1.72 4.915h14.82a2.29 2.29 0 002.202-2.907z"
        fill="#E7E7E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.857 45.703h5.883a.633.633 0 010 1.266H4.613c-1.405 0-2.594.823-2.594 1.797v4.989a.633.633 0 11-1.265 0v-4.989c0-1.689 1.73-3.063 3.86-3.063h9.765l.432-1.232H9.818a2.933 2.933 0 01-2.813-2.137L2.81 27.284a2.904 2.904 0 01.487-2.553 2.894 2.894 0 012.325-1.157h27.706c1.303 0 2.46.879 2.812 2.137l4.194 15.05a2.9 2.9 0 01-.485 2.553 2.898 2.898 0 01-2.326 1.157H23.15l-.43 1.232h20.554V37.22c0-4.152 3.375-7.531 7.521-7.531h6.543c4.147 0 7.52 3.379 7.52 7.531v8.482zM9.818 43.205c-.74 0-1.395-.5-1.595-1.212L4.03 26.944c-.141-.503-.04-1.03.275-1.447a1.643 1.643 0 011.318-.656h27.706c.74 0 1.394.499 1.594 1.212l4.194 15.05c.141.502.04 1.03-.274 1.446a1.64 1.64 0 01-1.319.656H23.595l1.425-4.072a2.454 2.454 0 00-.31-2.218 2.436 2.436 0 00-1.985-1.027H19.75a2.73 2.73 0 00-2.575 1.83l-1.922 5.486H9.818zm34.72 2.498h19.054V37.22c0-3.455-2.807-6.265-6.255-6.265h-6.543c-3.45 0-6.256 2.81-6.256 6.265v8.482zm-28.819 0h5.66l2.448-6.992a.536.536 0 00.021-.167c.001-.03.002-.061.005-.092.032-.282-.007-.563-.175-.802a1.155 1.155 0 00-.953-.494H19.75c-.622 0-1.178.395-1.383.983L16.3 44.047v.002l-.58 1.654z"
        fill="#231F20"
      />
    </svg>
  );
}

export default SvgComponent;
