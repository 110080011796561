import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { config } from "../../config";
import { makeRootStyles } from "../../theme/styles";
import { getBorderUnselected } from "../ChooseItems/utils";
import { omit } from "lodash/fp";

const useStyles = makeRootStyles(
  theme => ({
    root: {
      marginBottom: theme.spacing(2),
    },
    detailHeading: {
      marginBottom: theme.spacing(1),
    },
    detailHeadingValue: {
      ...omit(["fontFamily"], theme.typography.h2),
    },
    grid: {
      [theme.breakpoints.up("md")]: {
        borderRight: getBorderUnselected(theme),

        "&:last-child": {
          borderRightWidth: 0,
        },
      },
    },
  }),
  { name: "N0ReviewDetails" },
);

const DetailHeading = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.detailHeading} align="center" variant="h4">
      {children}
    </Typography>
  );
};

const DetailHeadingValue = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.detailHeadingValue}
      color="secondary"
      align="center">
      {children}
    </Typography>
  );
};

function Details({
  refundTotal,
  returnItemCount,
  receiveItemCount,
  returnMethodDisplayName,
  isCharge,
  isPreCharge,
}) {
  const classes = useStyles();
  const hasReturnMethod = !!returnMethodDisplayName;

  const title = (() => {
    if (isPreCharge) {
      return "Refund Total (pre charged)";
    }

    if (isCharge) {
      return config.translations.return_review_charge_total;
    } else {
      return config.translations.return_review_estimated_refund + "*";
    }
  })();

  return (
    <Grid
      justifyContent="center"
      className={classes.root}
      spacing={2}
      container>
      <Grid xs={12} md={3} item className={classes.grid}>
        <DetailHeading>{title}</DetailHeading>
        <DetailHeadingValue>{refundTotal}</DetailHeadingValue>
      </Grid>
      <Grid xs={6} md={3} item className={classes.grid}>
        <DetailHeading>
          {config.translations.return_review_returning}
        </DetailHeading>
        <DetailHeadingValue>{returnItemCount}</DetailHeadingValue>
      </Grid>
      {receiveItemCount > 0 && (
        <Grid xs={6} md={3} item className={classes.grid}>
          <DetailHeading>
            {config.translations.return_review_receiving}
          </DetailHeading>
          <DetailHeadingValue>{receiveItemCount}</DetailHeadingValue>
        </Grid>
      )}
      {hasReturnMethod && (
        <Grid xs={12} md={3} item className={classes.grid}>
          <DetailHeading>
            {config.translations.return_review_return_method}
          </DetailHeading>
          <DetailHeadingValue>{returnMethodDisplayName}</DetailHeadingValue>
        </Grid>
      )}
    </Grid>
  );
}
export default Details;
