import React, { useEffect, useState, useMemo, useCallback } from "react";
import { find } from "lodash/fp";
import { Grid, Typography, Button } from "@material-ui/core";

import {
  METHOD_IN_STORE,
  METHOD_SHIP_ON_YOUR_OWN,
  METHOD_KEEP_THE_ITEM,
  BOXLESS_METHOD,
  PRINTERLESS_METHOD,
  METHOD_XPO,
} from "../../../retailer-app/constants/returns";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import Spinner from "../../../shared/components/Spinner";
import { makeRootStyles } from "../../theme/styles";
import {
  useReturnStepsActions,
  CONFIRM_MAILING_ADDRESS_STEP,
  CHOOSE_METHOD_SUBSTEP,
  useReturnStepsState,
  CHOOSE_METHOD_STEP,
  useReturnStepIsActive,
  useRegisterStepStatus,
} from "../../contexts/returnSteps";
import { config } from "../../config";
import { useReturnMethods } from "../../data/returnMethods";
import MethodCard from "./MethodCard";
import { buildItemsByType } from "../../modules/items";
import locale from "../../../shared/modules/lang";
import { getTranslation } from "../../../shared/modules/config";
import InStoreIcon from "../../components/icons/return_in_store";
import DropOffIcon from "../../components/icons/dropoff";
import MailInIcon from "../../components/icons/use_my_own";
import MailingAddressForm from "../ConfirmMailingAddress/MailingAddressForm";
import {
  normalizeAddressKeys,
  mergeAddress,
  patchZipCode,
} from "../../modules/address";
import {
  getHandledErrorMessage,
  getIneligibleItemsReturnedError,
  getIneligibleReturnMethodError,
} from "../../../shared/modules/decodeError";
import { PudoRaw } from "@narvar/nth-kit-returns-headless";
import { useReturnLocations } from "../../data/returnLocations";
import { useTypeForm } from "../../contexts/typeForm";
import { useNthReturnsActions } from "../../contexts/nthContext";
import {
  isPreferredMethod,
  toReturnMethodPreference,
  useConsumerPreferences,
} from "../../data/consumerPreferences";
import titan from "../../../shared/modules/titan";
import useConsumerSettings from "../../hooks/useConsumerSettings";

const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    carrierLogo: {
      transform: "scale(1.5)",
      minWidth: "100px",
    },
    errorItemsContainer: {
      padding: theme.spacing(1),
    },
    errorItemsList: {
      listStyleType: "circle",
      listStylePosition: "inside",
    },
    submitContainer: {
      marginTop: theme.spacing(4),
    },
    chooseButton: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: "N0ChooseMethod" },
);

const computeMethodId = method => `${method?.id}|${method?.carrierService}`;

const ChooseMethod = ({ orderFetchData }) => {
  const classes = useStyles();

  const state = useReturnStepsState();
  const actions = useReturnStepsActions();
  const useNewDesign = config.isNthUi;
  const nthReturnsActions = useNthReturnsActions();

  const { order, returnReasonsById = {} } = orderFetchData;
  const { itemsById } = order;

  const { exchangeItems, returnItems } = useMemo(
    () => buildItemsByType(state.items, itemsById, returnReasonsById),
    [itemsById, returnReasonsById, state.items],
  );

  const returnMethodsPayload = {
    returnItems,
    exchangeItems,
    orderNumber: state.orderNumber,
    email: state.email,
    locale,
    csid: state.csid,
  };
  const {
    queryStatus,
    returnMethods,
    addressError,
    refetch,
  } = useReturnMethods(returnMethodsPayload);

  const [errorMessage, setErrorMessage] = useState(null);
  const [errorItems, setErrorItems] = useState([]);
  const returnMethodsFiltered = useMemo(
    () => returnMethods.filter(method => method.id !== METHOD_KEEP_THE_ITEM),
    [returnMethods],
  );

  const keepTheItemMethod = find({ id: METHOD_KEEP_THE_ITEM }, returnMethods);
  const allItemsAreKeepTheItem = useMemo(() => {
    if (!keepTheItemMethod) {
      return false;
    }

    const allReturnItemsAreKept = !returnItems.find(
      ri => keepTheItemMethod.keepItemIds.indexOf(ri.lineItemId) === -1,
    );
    const allExchangeItemsAreKept = !exchangeItems.find(
      ei =>
        keepTheItemMethod.keepItemIds.indexOf(ei.returnItem.lineItemId) === -1,
    );
    return allReturnItemsAreKept && allExchangeItemsAreKept;
  }, [keepTheItemMethod, returnItems]);

  const isSkipStep = allItemsAreKeepTheItem;

  const isValidSelected = useMemo(() => {
    const method = state.selectedReturnMethod;
    if (!method?.id) return false;
    return returnMethods.some(
      d => computeMethodId(d) === computeMethodId(method),
    );
  }, [state.selectedReturnMethod, returnMethods]);

  const {
    consumerPreferences,
    preferencesStatus,
    queryStatus: consumerPreferencesStatus,
  } = useConsumerPreferences(state.guestReturn ? "" : state.email);

  const isActiveStep = useReturnStepIsActive(CHOOSE_METHOD_STEP);
  const loading = queryStatus.loading || consumerPreferencesStatus.loading;
  const showNextBtn = !loading && !errorMessage && isActiveStep;

  useEffect(() => {
    if (returnMethods.length > 0) {
      setErrorMessage(null);
    }

    // Redirect to Review Step
    // If all items in order are "Keep the Item", send user directly to Review Step
    if (isSkipStep) {
      const method = keepTheItemMethod;
      actions.setReturnMethod(method);
      actions.goToStep(CONFIRM_MAILING_ADDRESS_STEP);

      // set a placeholder return cart for the new nth ui
      nthReturnsActions.setReturnCart?.({
        id: "",
        retailerMoniker: "",
        orderId: "",
        createdDate: "",
        expirationDate: "",
        lastUpdatedDate: "",
        pudoOption: {},
      });
    }
  }, [actions, isSkipStep, returnMethods.length]);

  useEffect(() => {
    if (keepTheItemMethod) {
      actions.setKeepItemIds(keepTheItemMethod.keepItemIds);
    }
  }, [actions, keepTheItemMethod]);

  // auto select the preferred method or the first method when methods loaded
  useEffect(() => {
    const method = state.selectedReturnMethod;
    if (
      !isSkipStep &&
      !method?.id &&
      returnMethodsFiltered.length > 0 &&
      !consumerPreferencesStatus.loading
    ) {
      const preferredMethod =
        returnMethodsFiltered.find(m =>
          isPreferredMethod(m, consumerPreferences),
        ) ?? returnMethodsFiltered[0];
      actions.setReturnMethod(preferredMethod);
    }
  }, [
    isSkipStep,
    state.selectedReturnMethod,
    returnMethodsFiltered,
    consumerPreferencesStatus,
  ]);

  // General error handling for queryStatus
  useEffect(() => {
    const errorMessage = getHandledErrorMessage(
      queryStatus.error,
      config.translations.general_loading_error,
    );
    setErrorMessage(errorMessage);
  }, [queryStatus]);

  // rule engine throw errors based on return methods (eg. 2 different return
  // items need to ship to different location)
  useEffect(() => {
    const error = getIneligibleReturnMethodError(queryStatus.error);
    if (error) {
      setErrorMessage(getTranslation("choose_method_no_intersection"));
    }
  }, [queryStatus]);

  // rule engine throw errors based on return items properties (eg. total value,
  // total items quantity)
  useEffect(() => {
    if (errorMessage) return;

    const error = getIneligibleItemsReturnedError(queryStatus.error);
    if (error) {
      const extensions = error.extensions;
      const errorItems = Object.keys(extensions.data).map(lineItemId => {
        const ineligibleReasons = extensions.data[lineItemId];
        if (ineligibleReasons.length) {
          const itemName = itemsById[lineItemId].name;
          return `${itemName} - ${ineligibleReasons.join(", ")}\n`;
        }
        return "";
      });

      setErrorMessage(error.message);
      setErrorItems(errorItems);
    }
  }, [queryStatus, errorMessage]);

  const createChooseMethodHandler = method => () => {
    if (computeMethodId(method) === computeMethodId(state.selectedReturnMethod))
      return;
    actions.setReturnMethod(method);
  };

  const handleNextStep = useCallback(() => {
    if (state.selectedReturnMethod.shouldDisplayLocations) {
      actions.goToStep(CHOOSE_METHOD_SUBSTEP);
    } else {
      actions.goToStep(CONFIRM_MAILING_ADDRESS_STEP);
    }
  }, [state.selectedReturnMethod]);

  const methodIcons = carrier => {
    switch (carrier) {
      case METHOD_IN_STORE:
        return <InStoreIcon name={null} carrier="in_store" />;
      case METHOD_SHIP_ON_YOUR_OWN:
        return <MailInIcon />;
      default:
        return <DropOffIcon />;
    }
  };

  const getMethodCardStrings = method => {
    const subText = method.displayDescription;
    if (method.qr === BOXLESS_METHOD) {
      return {
        qrText: config.translations.choose_method_substep_boxless_subtitle,
        subText: config.translations.choose_method_substep_boxless_description,
      };
    } else if (method.qr === PRINTERLESS_METHOD) {
      return {
        qrText: config.translations.choose_method_substep_printerless_subtitle,
        subText,
      };
    } else {
      return {
        qrText: false,
        subText,
      };
    }
  };

  const handleAddressFix = ({ fromAddress }) => {
    setErrorMessage(null);
    actions.setCorrectedAddress(fromAddress);
    refetch({
      ...returnMethodsPayload,
      fromAddress,
    });
  };

  /***************************************************************************************************************
   * New design with `nth` components
   ***************************************************************************************************************/
  const typeForm = useTypeForm();
  const [selectedCategory, setSelectedCategory] = useState();
  const initialAddress = patchZipCode(
    mergeAddress(
      normalizeAddressKeys(order, "billing"),
      normalizeAddressKeys(order, "from"),
      state.fromAddress,
    ),
  );
  const { countryCode, provinceCode } = initialAddress;
  const [zipCode, setZipCode] = useState(initialAddress.zip);
  const {
    state: { data: locations, ...locationStatus },
    actions: { getLocations, reset: resetLocations },
  } = useReturnLocations();
  const [showSwitchCategory, setShowSwitchCategory] = useState(false);
  const { localeFull, includeCurrency } = useConsumerSettings();

  const selectedMethod = find(
    {
      id: selectedCategory?.pudoProvider?.moniker,
      carrierService: selectedCategory?.serviceType,
    },
    returnMethods,
  );

  const getCarrierForLocationSearch = selectedMethod =>
    selectedMethod?.id === METHOD_IN_STORE
      ? "in_store"
      : selectedMethod?.id === "ups" &&
        selectedMethod?.carrierService === "UPS Mail Innovations" // UPS Mail Innovations uses USPS drop-off locations for fulfillment.
      ? "usps_returns"
      : selectedMethod?.id;

  useEffect(() => {
    if (selectedMethod?.id && selectedMethod?.shouldDisplayLocations) {
      getLocations({
        countryCode,
        provinceCode,
        zipcode: zipCode,
        carrier: getCarrierForLocationSearch(selectedMethod),
        qr: selectedMethod.qr,
        locale,
      });
    }
  }, [selectedMethod, zipCode]);

  const pudoOptions = useMemo(
    () =>
      returnMethodsFiltered.map(method => {
        const isPickup = method.id === METHOD_XPO;
        const ret = {
          pudoActionType: isPickup ? "PICK_UP" : "DROP_OFF",
          pudoActionTypeDetails: method.qr ? "PRINTERLESS" : "LABEL",
          pudoProvider: {
            moniker: method.id,
            type:
              method.id === METHOD_IN_STORE
                ? "STORE"
                : method.id === METHOD_SHIP_ON_YOUR_OWN
                ? "SELF"
                : isPickup
                ? "COURIER"
                : "CARRIER",
            iconUrl: null,
          },
          serviceType: method.carrierService,
          title: method.displayName,
          description: method.displayDescription,
          fees: config.hideReturnMethodPrice
            ? []
            : [
                // nth supports "LABEL_FEE" and "SERVICE_FEE", to avoid bugs, we
                // sum all fess, and only pass one fee to nth component
                {
                  type: null,
                  amount: {
                    value: method.feeBreakdown
                      ?.filter(fee => fee.displayWithReturnMethod)
                      ?.reduce((p, c) => p + c.fee, 0),
                    currency: method.feeBreakdown?.[0]?.currency ?? null,
                  },
                },
              ],
          pickup: null,
          dropOff: null,
          preferred: isPreferredMethod(method, consumerPreferences),
          _raw: method,
        };
        return ret;
      }),
    [returnMethodsFiltered, consumerPreferences],
  );

  const pudoLocations = useMemo(
    () =>
      selectedMethod?.shouldDisplayLocations
        ? locations.map(location => {
            const isInStore = selectedMethod.id === METHOD_IN_STORE;
            let logo = location.logoUrl;
            if (
              !logo &&
              isInStore &&
              config.logoImg &&
              !/no-image/.test(config.logoImg)
            ) {
              // remove the image tag
              logo = config.logoImg.replace(
                /^<img.* src="([^"]*)".*\/>$/,
                "$1",
              );
            }
            const ret = {
              ...location,
              storeId: location.id,
              displayName: location.storeName,
              locationType: location.storeName2,
              logoUrls: logo ? [logo] : null,
              openingHours: [],
              brand: null,
              companyName: null,
              distance: null,
              phoneNumber: location.storeAddress.phone,
              address: {
                ...location.storeAddress,
                regionCode: location.storeAddress.state,
                postalCode: location.storeAddress.zip,
                countryCode,
              },
            };
            return ret;
          })
        : [],
    [countryCode, selectedMethod, locations],
  );

  // auto select method when methods loaded
  useEffect(() => {
    if (!isSkipStep && !selectedCategory && pudoOptions.length === 1) {
      setSelectedCategory(pudoOptions[0]);
    }
  }, [isSkipStep, selectedCategory, pudoOptions]);

  const [preferencesLoaded, setPreferencesLoaded] = useState(false);
  useEffect(() => {
    if (
      !isSkipStep &&
      !selectedCategory &&
      pudoOptions.length > 1 &&
      !consumerPreferencesStatus.loading &&
      !preferencesLoaded
    ) {
      const preferred = pudoOptions.find(d => d.preferred);
      if (preferred) {
        setSelectedCategory(preferred);
        setPreferencesLoaded(true);
        setShowSwitchCategory(true);

        if (consumerPreferences?.profile?.profileId) {
          titan.identify(consumerPreferences.profile.profileId);
        }

        titan.track("products_list_viewed", {
          props: {
            list_id: "pudo_options",
            products: pudoOptions.map(opt => ({
              product_id: toReturnMethodPreference(opt._raw),
              variant: opt.preferred ? "PREFERRED" : null,
            })),
          },
        });
      }
    }
  }, [
    isSkipStep,
    selectedCategory,
    pudoOptions,
    consumerPreferencesStatus,
    preferencesLoaded,
  ]);

  // When category was selected and locations were loaded, if there is no
  // selectedDropOff or it has an incorrect value, auto-select the first
  // location in the list
  useEffect(() => {
    if (
      isActiveStep &&
      !selectedCategory?.skippedDropOffLocation &&
      pudoLocations?.length > 0 &&
      !find(
        { id: selectedCategory.selectedDropOff?.selectedLocation?.id },
        pudoLocations,
      )
    ) {
      setSelectedCategory({
        ...selectedCategory,
        selectedDropOff: { selectedLocation: { id: pudoLocations[0].id } },
      });
    }
  }, [isActiveStep, selectedCategory, pudoLocations]);

  useRegisterStepStatus(CHOOSE_METHOD_STEP, { skipped: isSkipStep });
  if (isSkipStep) return null;

  if (addressError) {
    return (
      <Grid
        className={classes.submitContainer}
        container
        justifyContent="center"
        alignItems="center">
        <Typography align="center" className={classes.title} variant="h1">
          {config.translations.mailing_address_section_title}
        </Typography>
        <MailingAddressForm
          address={mergeAddress(state.fromAddress, addressError.address)}
          onSubmit={handleAddressFix}
          disable={addressError.disableFields}
          validateZip={true}
        />
      </Grid>
    );
  }

  if (useNewDesign) {
    const error = errorMessage ? (
      <>
        <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        {errorItems && errorItems.length > 0 && (
          <div className={classes.errorItemsContainer}>
            <ul className={classes.errorItemsList}>
              {errorItems.map((item, i) => {
                return (
                  <li key={item + i}>
                    <span dangerouslySetInnerHTML={{ __html: item }} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </>
    ) : null;

    return (
      <Grid
        className={classes.root}
        justifyContent="center"
        spacing={2}
        container>
        <Grid xs={12} item>
          <PudoRaw
            locale={localeFull}
            includeCurrency={includeCurrency}
            progressiveStep
            activeStep={isActiveStep}
            // // headerTitle={<span>Hi</span>}
            error={error}
            loading={loading}
            loadingLocations={locationStatus.loading}
            pudoOptions={pudoOptions}
            selectedCategory={selectedCategory}
            postalCode={zipCode}
            postalCodeError={locationStatus.error}
            // // sortedBy={}
            // // sortedByOptions={}
            showSkipLocation={
              selectedCategory?.pudoProvider?.moniker !== METHOD_IN_STORE
            }
            showSwitchCategory={showSwitchCategory}
            locations={pudoLocations}
            // returnAddress={args.customer?.shippingAddress} // customer / pickup address
            preferencesStatus={preferencesStatus}
            multiLabelsEnabled={selectedMethod?.maxLabelRequests > 1}
            labelsCount={state.requestedLabelCount}
            labelsMax={selectedMethod?.maxLabelRequests}
            onChange={event => {
              if (
                event.value?.selectedCategory ||
                event.subComponent === "category"
              ) {
                const skippedDropOffLocation =
                  event.subComponent === "location" &&
                  !event.value?.selectedCategory?.selectedDropOff;
                setSelectedCategory(
                  event.value?.selectedCategory
                    ? {
                        skippedDropOffLocation,
                        ...event.value?.selectedCategory,
                      }
                    : undefined,
                );
              }
              if (event.value?.postalCode) {
                setZipCode(event.value.postalCode);
              }
              if (event.value?.labelsCount !== undefined) {
                actions.setRequestedLabelCount(event.value.labelsCount);
              }
              actions.setReturnMethod(state.selectedReturnMethod); // reset step, clean up the UI when user clicking from a later step back to method selection

              // selecting new return method, then focus back location / pickup form
              if (event.subComponent === "category") {
                setShowSwitchCategory(false);
                typeForm.scrollToActive();
              }
            }}
            onBack={() => {
              if (selectedCategory && pudoOptions.length > 1) {
                setSelectedCategory(undefined);
                resetLocations();
              } else {
                actions.goBack();
              }
              typeForm.scrollToActive();
            }}
            onSubmit={e => {
              if (selectedMethod) {
                const pudoOption = e.value.selectedCategory;
                setSelectedCategory(pudoOption);
                nthReturnsActions.setSelectedPudoOption(pudoOption);

                const selectedLocation = find(
                  {
                    id: pudoOption?.selectedDropOff?.selectedLocation?.id,
                  },
                  locations,
                );
                const dropOffLocation = pudoLocations.find(
                  loc => loc.id === selectedLocation?.id,
                );

                const returnCart = {
                  id: "",
                  retailerMoniker: "",
                  orderId: "",
                  createdDate: "",
                  expirationDate: "",
                  lastUpdatedDate: "",
                  // customer?: Maybe<Customer>;
                  // items?: Maybe<Array<ReturnItem>>;
                  pudoOption: pudoOption
                    ? {
                        ...pudoOption,
                        selectedDropOff: dropOffLocation
                          ? { dropOffLocation }
                          : null,
                      }
                    : null,
                  // refundOption?: Maybe<RefundOption>;
                };
                nthReturnsActions.setReturnCart(returnCart);

                actions.setReturnMethod(selectedMethod);
                actions.setStoreLocation(
                  selectedLocation
                    ? {
                        ...selectedLocation,
                        carrier: getCarrierForLocationSearch(selectedMethod),
                      }
                    : {},
                );
                actions.goToStep(CONFIRM_MAILING_ADDRESS_STEP);
              }
            }}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      className={classes.root}
      justifyContent="center"
      spacing={2}
      container>
      <Grid xs={12} item>
        <Typography align="center" className={classes.title} variant="h1">
          {config.translations.choose_method_title}
        </Typography>
        {!addressError && errorMessage && (
          <Grid item xs={12} md={12}>
            <ErrorSnackbar
              style={{ height: "auto", whiteSpace: "pre-line" }}
              message={
                <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
              }
            />
            {errorItems && errorItems.length > 0 && (
              <div className={classes.errorItemsContainer}>
                <ul className={classes.errorItemsList}>
                  {errorItems.map((item, i) => {
                    return (
                      <li key={item + i}>
                        <span dangerouslySetInnerHTML={{ __html: item }} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </Grid>
        )}
        {loading ? (
          <Grid container spacing={2} justifyContent="center">
            <Grid xs={12} item>
              <Spinner />
            </Grid>
          </Grid>
        ) : (
          !errorMessage && (
            <Grid container spacing={2} justifyContent="center">
              {returnMethodsFiltered.map(method => {
                const { qrText, subText } = getMethodCardStrings(method);

                return (
                  <Grid key={computeMethodId(method)} md={4} xs={12} item>
                    <MethodCard
                      selected={
                        computeMethodId(state.selectedReturnMethod) ===
                        computeMethodId(method)
                      }
                      title={method.displayName}
                      subtitle={subText}
                      price={method.returnMethodDisplayPrice}
                      icon={methodIcons(method.id)}
                      onClick={createChooseMethodHandler(method)}
                      qr={qrText}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )
        )}
      </Grid>
      {showNextBtn && (
        <Grid xs={12} sm={6} md={4} item>
          <Button
            id="choose-method-next"
            className={classes.chooseButton}
            color="primary"
            disabled={!isValidSelected}
            onClick={handleNextStep}
            size="large"
            variant="contained"
            fullWidth>
            <strong>
              {config.translations.choose_method_next_button_label}
            </strong>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
export default ChooseMethod;
